import React, { Component } from 'react';
import * as d3 from 'd3';
import * as d3_annotation from 'd3-svg-annotation';

import Utilities from '../Utilities';

class IndividualKnot extends Component {
  _setRef(componentNode) {
    this._rootNode = componentNode;
  }

  componentDidMount(){
    const onClose = this.props.onClose;

    const data = this.props.data;
    this.svg = d3.select(this._rootNode);

    this.node = this.svg.selectAll('.node');
    this.node = this.node.data([data].map(n=>{return n;}), function(d) {return d.id;});
    this.node.exit().remove();
    this.node = this.node.enter().append("g")
        .classed('node', true)
        .attr('id',d=>'node-'+d.id)
        .merge(this.node)
        .on('click', (d)=>{
          const id = d.id;
          txt_bg.transition()
            .duration(Utilities.knot_settings.animationDuration)
            .style('opacity',0);
          circle.transition()
              .duration(Utilities.knot_settings.animationDuration)
              .attr('transform','translate(0,0)')
              .style('opacity',0);
          value.transition()
              .duration(Utilities.knot_settings.animationDuration)
              .attr('transform','translate(0,0)')
              .style('opacity',0);
          knotDashed.transition()
              .duration(Utilities.knot_settings.animationDuration)
              .attr('transform','translate(0,0)')
              .style('opacity',0);
          this.svg.select('.txt-line').transition()
              .duration(Utilities.knot_settings.animationDuration)
              .attr("font-size", Utilities.knot_settings.fontSize);
          annotations_svg_elements.transition()
              .duration(Utilities.knot_settings.animationDuration)
              .attr('transform','translate(0,0)')
              .style('opacity',0);
          txt_line.transition()
              .duration(Utilities.knot_settings.animationDuration)
              .style('font-variation-settings','"wght" 400');
          knot.transition()
              .duration(Utilities.knot_settings.animationDuration)
              .attr('d', d.path_instructions)
              .on('end', function(){
                d3.select(`#wrapper-opened-svg-${id}`)
                    .transition()
                        .duration(Utilities.knot_settings.animationDuration)
                        .style('opacity',0)
                        .on('end',()=>{
                          onClose(id);
                        });
              });
          
        });

    let rectMask = this.node.selectAll('.rect-mask');
    rectMask = rectMask.data(d=>{ return [d]});
    rectMask.exit().remove();
    rectMask = rectMask.enter().append("circle")
        .classed('rect-mask',true)
        .attr('fill',Utilities.colors[this.props.position].background)
        .merge(rectMask)
        .attr('r',d=>(d.r*1.1))
        .attr('x',d=>-0.5*d.r*1.1)
        .attr('y',d=>-0.5*d.r*1.1)

    let circle = this.node.selectAll(".circle-area");
    circle = circle.data(d=>{ return [d]})
    circle.exit().remove();
    circle = circle.enter().append("circle")
        .classed("circle-area", true)
        .attr("r", d=>d.r)
        .attr("fill", Utilities.colors[this.props.position].background)
        .attr('stroke', Utilities.colors[this.props.position].strokes)
        .attr('stroke-width',.5)
        .attr('stroke-position', 'inner')
        .style("opacity", 0)
        .attr('transform','translate(0,0)')
        .style('pointer-events','all')
        .merge(circle);

    let txt_bg = this.node.selectAll('.txt-bg');
    txt_bg = txt_bg.data(d=>[d]);
    txt_bg.exit().remove();
    txt_bg = txt_bg.enter().append("rect")
        .classed("txt-bg", true)
        .attr('fill', Utilities.colors[this.props.position].background)
        .merge(txt_bg)
        .attr('width', d=>d.supposed_knot_length_augmented + 10)
        .attr('height', 'calc('+Utilities.knot_settings.fontSizeAugmented+' + 6px)')
        .attr('x',d=>-0.5*d.supposed_knot_length_augmented - 5)
        .attr('y', 'calc(-'+Utilities.knot_settings.fontSizeAugmented+' - 3px)')
        .style('opacity',0);

    this.annotations = this.node
        .append("g")
        .attr("class", "annotation-group");
    let annotations_svg_elements = this.annotations;

    let value = this.node.selectAll('.value')
    value = value.data(d=>{
        return ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19"].map(dd=>{return{"id":d.id+dd, "n":dd,"r":d.r, data:d}})
    }, d=>d.id)
    value.exit().remove()
    value = value.enter().append("circle")
        .classed("value", true)
        .attr("id",d=>d.data.id+'-'+d.n)
        .attr("r", (d)=>{
            const property = Utilities.knots_grid_number(d.n).property;
            const value = Utilities.knots_grid_number(d.n).value;
            return d.data[property]===value ? 1.75 : 0.35;
        })
        .attr("cx", d=>Utilities.knots_grid_number(d.n).get_dx(d.r/5))
        .attr("cy", d=>Utilities.knots_grid_number(d.n).get_dy(d.r/5))
        .attr('transform','translate(0,0)')
        .attr("fill", Utilities.colors[this.props.position].lines)
        .style("opacity", 0)
        .merge(value);

    let knotDashed = this.node.selectAll(".knot-path-dashed");
    knotDashed = knotDashed.data(d=>{ d.points = Utilities.get_knot_points(d); return [d]; })
    knotDashed.exit().remove()
    knotDashed = knotDashed.enter().append("path")
        .attr('id', d=>'knot-path-dashed'+d.id)
        .classed("knot-path-dashed", true)
        .merge(knotDashed)
        .attr('d', d=>d.path_instructions)
        .attr('stroke', Utilities.colors[this.props.position].lines)
        .attr('stroke-dasharray', '4 1')
        .attr('stroke-width',.5)
        .attr('fill','none')
        .style('opacity',0);

    let knot = this.node.selectAll(".knot-path");
    knot = knot.data(d=>{ d.points = Utilities.get_knot_points(d); return [d]; })
    knot.exit().remove()
    knot = knot.enter().append("path")
        .attr('id', d=>'knot-path-'+d.id)
        .classed("knot-path", true)
        .merge(knot)
        .attr('d', d=>d.path_instructions)
        .attr('stroke', Utilities.colors[this.props.position].lines)
        .attr('stroke-width',.5)
        .attr('fill','none')
        .style('opacity',1);

    let txt_line = this.node.selectAll(".txt-line");
    txt_line = txt_line.data(d=>[d])
    txt_line.exit().remove();
    txt_line = txt_line.enter().append("text")
        .classed("txt-line", true)
        .attr("font-size", Utilities.knot_settings.fontSize)
        .style('font-variation-settings','"wght" 400')
        .attr('text-anchor','middle')
        .attr('dy', Utilities.knot_settings.dY)
        .merge(txt_line)
        .append('textPath')
        .attr("startOffset","50%")
        .attr("fill", Utilities.colors[this.props.position].texts)
        .attr('href', d=>'#knot-path-'+d.id)
        .style('pointer-events','all')
        .text(d=>d.line);

    this.bbox = this.node.node().getBoundingClientRect();
    const width = Math.max(this.props.data.supposed_knot_length, 500)// * this.props.visualizationTransformation.k;
    const height = Math.max(this.props.data.r*6, 500) //(this.bbox.height + 4) / this.props.visualizationTransformation.k;

    this.node.attr("transform", `translate(${width/2}, ${height/2})`)

    this.svg
      .attr("width", width)
      .attr("height", height)  
      .style("top", (data[`mds_${this.props.position}_y`])+'px' )
      .style("left", (data[`mds_${this.props.position}_x`])+'px' )

    this.svg.select('.txt-line').transition()
      .duration(Utilities.knot_settings.animationDuration)
      .attr("font-size", Utilities.knot_settings.fontSizeAugmented)
      .style('font-variation-settings','"wght" 550');

    let increment = this.props.data.supposed_knot_length_augmented - this.props.data.supposed_knot_length;
    let unraveled = JSON.parse(JSON.stringify(this.props.data.unraveled_path_instructions));
    let unraveledSplitten = unraveled.split(',');
    unraveledSplitten[0] = 'M' + (Number(unraveledSplitten[0].replace('M','')) - increment/2).toString();
    unraveledSplitten[unraveledSplitten.length-2] = (Number(unraveledSplitten[unraveledSplitten.length-2]) + increment/2).toString();
    let unraveled_extended = unraveledSplitten.join(',');

    knot.transition()
      .duration(Utilities.knot_settings.animationDuration)
      .attr('d', unraveled_extended)
      .on('end',()=>{

        txt_bg.transition()
          .duration(Utilities.knot_settings.animationDuration)
          .style('opacity',1);

        const translation_y = -this.props.data.r*1.75
        circle.transition()
          .duration(Utilities.knot_settings.animationDuration)
          .attr('transform',d=>'translate(0,'+translation_y+')')
          .style("opacity", 1)
          // .on('end', (d)=>{
          // });

        value.transition()
          .duration(Utilities.knot_settings.animationDuration)
          .attr('transform',d=>'translate(0,'+translation_y+')')
          .style("opacity", 1);

        knotDashed.transition()
          .duration(Utilities.knot_settings.animationDuration)
          .attr('transform',d=>'translate(0,'+translation_y+')')
          .style("opacity", 0.75);
        
        this.annotations.transition()
          .duration(Utilities.knot_settings.animationDuration)
          .attr('transform',d=>'translate(0,'+translation_y+')')
          .style('opacity', 1);
      });

      // Annotations
      const type = d3_annotation.annotationCustomType(
        d3_annotation.annotationLabel, 
        {"className":"custom",
          "note":{"align":"middle",
          "orientation":"leftRight"}})

      const annotations = this.props.data.knot.map(n=>{
        const object = {
          note: {
            title: Utilities.data_values_labels[Utilities.knots_grid_number(n).property],
            label: Utilities.data_values_labels[Utilities.knots_grid_number(n).value],
            // bgPadding: {top: '0.2rem', right: '0.3rem', bottom: '0.2rem', left: '0.3rem'},
            bgPadding: 3
          },
          className: "show-bg",
          x: Utilities.knots_grid_number(n).get_dx(this.props.data.r/5),
          y: Utilities.knots_grid_number(n).get_dy(this.props.data.r/5),
          nx: Utilities.annotations_positions[Utilities.knots_grid_number(n).property].x*this.props.data.r,
          ny: Utilities.annotations_positions[Utilities.knots_grid_number(n).property].y*this.props.data.r
        }
        return object;
      })

      const makeAnnotations = d3_annotation.annotation()
        .notePadding(3)
        .type(type)
        .annotations(annotations);

      document.fonts.ready.then(()=>{
        this.annotations
          // .attr("transform",`translate(${width/2}, ${height/2})`)
          .style('font-size', Utilities.knot_settings.annotationSize)
          .style('opacity', 0)
          .call(makeAnnotations);
        this.svg.selectAll('.annotation-group .annotation text')
          .attr('fill', 'var(--var-col-text)');
        this.svg.selectAll('.annotation-connector')
          .style('stroke', Utilities.colors[this.props.position].lines)
          .style('stroke-width', Utilities.knot_settings.annotationStrokeWidth)
          .style('stroke-dasharray', Utilities.knot_settings.annotationStrokeDasharray)
          .style('stroke-linecap', Utilities.knot_settings.annotationLinecap);
        this.svg.selectAll('.annotation-note-bg')
          .style("fill", Utilities.colors[this.props.position].background)
          .style("stroke", Utilities.colors[this.props.position].texts);
        this.svg.selectAll('.annotation-note-content text')
          .style("fill", Utilities.colors[this.props.position].texts);
      });
  }

  componentDidUpdate(prevProps){
    if (prevProps.position !== this.props.position) {
      console.log('update individual knot SVG')
      this.svg
        .style("top", (this.props.data[`mds_${this.props.position}_y`])+'px' )
        .style("left", (this.props.data[`mds_${this.props.position}_x`])+'px' );
      console.log('update colors');

      //update color of text
      d3.selectAll('.knot-path').attr("stroke", Utilities.colors[this.props.position].lines);
      d3.selectAll('text').attr("fill", Utilities.colors[this.props.position].texts);
      d3.selectAll('text textPath').attr("fill", Utilities.colors[this.props.position].texts);
    }
  }

  componentWillUnmount() {
    // console.log("component unmounted");
  }

  render() {
    const style = {
      transition: "top 0.5s ease, left 0.5s ease",
      position: 'absolute',
      transform: 'translate(-50%,-50%)'
    }
    return <svg className="individual-knot" style={style} ref={this._setRef.bind(this)}></svg>;
  }
}

export default IndividualKnot;
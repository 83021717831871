import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { BsExclamationDiamondFill } from "react-icons/bs";
import styles from "./HomePage.module.css";
import vialab from "./vialab.png";
import density from "./density.png";
import ontariotech from "./ontariotech.png";
import * as d3 from "d3";
import knot from "./knot.json";
import interfaceHowTo from "./interface.png";
import pictorial from "./Tied in Knots - VISAP2020.pdf";
import paper from "./Visualizing stories of sexual harassment in the academy_ Communit.pdf";

// const pictorial =
//   "https://conferences.computer.org/vis/pdfs/VISAP2020-5TfMB8mjKX7pK8Kqp6NbZE/855300a029/855300a029.pdf";

const authors = [
	{
		label: "Tommaso Elli",
		url: "https://densitydesign.org/person/tommaso-elli/",
	},
	{ label: "Adam Bradley", url: "http://adamjamesbradley.com/" },
	{
		label: "Christopher Collins",
		url: "http://vialab.science.uoit.ca/portfolio/christopher-m-collins",
	},
	{
		label: "Uta Hinrichs",
		url: "https://sachi.cs.st-andrews.ac.uk/people/faculty/uta-hinrichs/",
	},
	{ label: "Zachary Hills", url: "https://zachary-hills.ca/" },
	{
		label: "Karen Kelsky",
		url: "https://theprofessorisin.com/about-the-professor-2/",
	},
];

const HomePage = () => {
	const svgRef = useRef(null);
	useEffect(() => {
		const boundingClientRect = svgRef.current.getBoundingClientRect();
		const height = boundingClientRect.height;
		const width = height * 3;
		const svg = d3.select(svgRef.current);
		const scale = height / 70;
		svg.attr("viewBox", `0 0 ${width} ${height}`);

		// svg.style("background-color",'white');
		// svg.append("rect")
		//   .attr("width","100%")
		//   .attr("height","100%")
		//   .attr("fill","var(--var-col-main)");

		const g = svg.append("g").attr("transform", `translate(${width / 2}, ${height / 2.3}) scale(${scale})`);

		const path = g
			.append("path")
			.attr("id", "animated-knot-home")
			.attr("d", knot.path_instructions)
			.attr("fill", "none")
			.attr("stroke", "1");

		repeat();
		function repeat() {
			path
				.transition()
				.duration(3000)
				.attr("d", knot.unraveled_path_instructions)
				.transition()
				.duration(3000)
				.attr("d", knot.path_instructions)
				.on("end", repeat);
		}
		const message = "Visualizing testimonies of sexual harassment in the academy";
		g.append("text")
			.append("textPath")
			.attr("font-size", "6.5px")
			.attr("href", "#animated-knot-home")
			.attr("text-anchor", "middle")
			.attr("text-decoration", "underline")
			.attr("startOffset", "50%")
			.text(message);
	}, [svgRef]);

	return (
		<>
			<div className={styles.main}>
				<header className={styles["home-header"]}>
					<h1
						style={{
							gridColumn: "2 / span 4",
							alignSelf: "center",
						}}
					>
						Academia is Tied in Knots
					</h1>
					<svg
						ref={svgRef}
						style={{
							gridColumn: "1 / span 6",
							textAlign: "center",
							alignSelf: "center",
							width: "100%",
							maxHeight: "100%",
						}}
						preserveAspectRatio="xMidYMid meet"
					/>
				</header>
				<p className={styles.big}>
					A data visualization project aimed at giving visibility to the issue of sexual harassment in the academy.
				</p>
				<p id="about">
					As researchers and members of academic community we felt that this issue goes too often under-reported and we
					decided to give visibility to it using data-visualization as a communicative medium.
				</p>
				<p>
					The data you are about to see comes from an anonymous online survey aimed at collecting personal experiences.
					The survey was issued in late 2017 and, through it, more than 2000 testimonies were collected.
				</p>
				<p>
					This data is highly personal and sensitive. We spent significant effort identifying suitable ways to handle
					and represent it, to show the large dataset, but also honor the individual experiences.
				</p>
				<p>
					Learn{" "}
					<a href={interfaceHowTo} target="_blank" rel="noopener noreferrer">
						here
					</a>{" "}
					how to interact with the visualization.
				</p>
				<p style={{ marginBottom: 0 }}>If you want to know more, have a look at our publications:</p>
				<ul style={{ gridColumn: "2 / 6", marginTop: 0 }}>
					<li style={{ listStyleType: "disclosure-closed" }}>
						<p>
							[
							<a href={paper} download>
								conference paper
							</a>
							] Elli, T., Bradley, A., Hinrichs, U., and Collins, C. (2022) Visualizing stories of sexual harassment in
							the academy: Community empowerment through qualitative data, in Lockton, D., Lenzi, S., Hekkert, P., Oak,
							A., Sádaba, J., Lloyd, P. (eds.), DRS2022: Bilbao, 25 June - 3 July, Bilbao, Spain.
							https://doi.org/10.21606/drs.2022.447
						</p>
					</li>
					<li style={{ listStyleType: "disclosure-closed" }}>
						<p>
							[
							<a href={pictorial} download>
								VISAP pictorial
							</a>
							] T. Elli, A. Bradley, C. Collins, U. Hinrichs, Z. Hills and K. Kelsky, "Tied in Knots: A Case Study on
							Anthropographic Data Visualization About Sexual Harassment in the Academy," 2020 IEEE VIS Arts Program
							(VISAP), Salt Lake City, UT, USA, 2020, pp. 29-44, doi: 10.1109/VISAP51628.2020.00010.
						</p>
					</li>
				</ul>
				<p className={styles["responsive-message"]}>
					<BsExclamationDiamondFill /> <BsExclamationDiamondFill /> <BsExclamationDiamondFill />
					<br />
					<br />
					This website is designed for bigger screens, we recommend you to browse it with your laptop or tablet.
					<br />
					<br />
					<BsExclamationDiamondFill /> <BsExclamationDiamondFill /> <BsExclamationDiamondFill />
				</p>
				<Link
					to="/visualization"
					className={[styles["tin-button"], styles["tin-button-big"], styles["big-explore-button"]].join(" ")}
				>
					Explore the visualization
				</Link>
				<p style={{ textAlign: "center" }}>~</p>
			</div>
			<div className={[styles.footer].join(" ")}>
				<div className={styles.main}>
					<span className={styles.credits}>
						<h5>Credits</h5>
						<ul>
							{authors.map((a, i) => (
								<li key={i}>
									<a href={a.url}>{a.label}</a>
								</li>
							))}
						</ul>
					</span>
					<span className={styles.logos}>
						<h5>&nbsp;</h5>
						<span>
							<img
								className={[styles.logo, "cursor-pointer"].join(" ")}
								src={ontariotech}
								onClick={() => {
									const win = window.open("https://ontariotechu.ca/", "_blank");
									win.focus();
								}}
							/>
							<img
								className={[styles.logo, "cursor-pointer"].join(" ")}
								src={vialab}
								onClick={() => {
									const win = window.open("http://vialab.science.uoit.ca/", "_blank");
									win.focus();
								}}
							/>
							<img
								className={[styles.logo, "cursor-pointer"].join(" ")}
								src={density}
								onClick={() => {
									const win = window.open("http://densitydesign.org/", "_blank");
									win.focus();
								}}
							/>
						</span>
					</span>
				</div>
			</div>
		</>
	);
};

export default HomePage;

import React, { Component } from 'react';
import * as PIXI from "pixi.js";
import { Viewport } from "pixi-viewport";
import * as d3 from "d3";
// import _ from "lodash";
import Utilities from "../Utilities";

const dpi = window.devicePixelRatio;
// console.log('dpi', dpi);
const supported_dpi = 2;
const atlases_amount = 4;
const atlasBaseName = "./atlases/spritesheet-"; // the base name of the images files containing pictures and of the jsons that describe their positions in the atlas

const simulation = d3.forceSimulation()
  .force("x", d3.forceX(d=>d.x))
  .force("y", d3.forceY(d=>d.y))
  .stop();

let pixiApp, viewport, particleContainers = [], graphics, highlights;

class KnotsPixi2 extends Component {
  constructor(props) {
    super(props);

    this.loadData = this.loadData.bind(this);
    this.createPixiContainers = this.createPixiContainers.bind(this);
    this.createSprites = this.createSprites.bind(this);
    this.contains = this.contains.bind(this); 
    this.getUV = this.getUV.bind(this); 
    this.getGroup = this.getGroup.bind(this); 
    this.getSprite = this.getSprite.bind(this);
    this.highlightVisuals = this.highlightVisuals.bind(this);

    this.updateVisuals = this.updateVisuals.bind(this);
    this.repositionVisuals = this.repositionVisuals.bind(this);

    PIXI.settings.MIPMAP_TEXTURES = PIXI.MIPMAP_MODES.ON;
    // PIXI.SCALE_MODES.NEAREST=1;
    // PIXI.SCALE_MODES.LINEAR=1;

    this.pixiApp = pixiApp;
    this.viewport = viewport;
    this.graphics = graphics;
    this.highlights = highlights;
    this.previousPlaying = '';

    this.state = {};

  }

  async loadData(){
    // console.log('load data')
    for (let i = 1; i <= atlases_amount; ++i) {
      let res = await d3.json(atlasBaseName + `${i}.json`);
      this.atlasesIndexes.push(res);
      for (const frame in res.frames) {
        this.info[frame] = i;
      }
      PIXI.Loader.shared.add(`atlas${i}`, atlasBaseName + `${i}.png`);
    }
    PIXI.Loader.shared.on("complete", (loader, resources)=>{
      // console.log("complete 👍");
      for (let i = 1; i <= atlases_amount; ++i) {
        this.atlas.push(resources[`atlas${i}`].texture);
      }
      // now create containers
      // console.log('data and images are loaded')
      this.createPixiContainers();
    });
    PIXI.Loader.shared.load();
  }

  createPixiContainers(){
    // console.log('create containers');
    for (let i = 0; i < atlases_amount; ++i) {
      const this_particle_container = new PIXI.Container(
        this.props.data.length,
        {
          position: true,
          tint: true,
          uvs: true
        }
      );
      // 🚨🚨🚨🚨🚨
      // this_particle_container.position = new PIXI.Point(
      //   window.innerWidth / 2,
      //   window.innerHeight / 2
      // );
      this_particle_container.setTransform(window.innerWidth/2, window.innerHeight/2)


      this_particle_container.interactiveChildren = true;
      this_particle_container.interactive = true;

      particleContainers.push(this_particle_container);
      viewport.addChild(particleContainers[particleContainers.length - 1]);
    }
    this.createSprites();
  }

  createSprites(){
    // console.log('create sprites');
    for (let i = 0; i < this.props.data.length; ++i) {
      if (this.contains(this.props.data[i].id)) {
        let frame = this.getUV(this.props.data[i].id);
        let groupID = this.getGroup(this.props.data[i].id);
        let texture = new PIXI.Texture(
          this.atlas[groupID],
          new PIXI.Rectangle(frame.x, frame.y, frame.w, frame.h)
        );
        let sprite = new PIXI.Sprite(texture);
        const x = this.props.data[i].x;
        const y = this.props.data[i].y;
        sprite.position.set(x, y);
        sprite.anchor.set(0.5, 0.5);
        sprite.scale.x = 1 / (supported_dpi * Utilities.zoomOptions.maxScale);
        sprite.scale.y = 1 / (supported_dpi * Utilities.zoomOptions.maxScale);
        sprite.tint = Utilities.colors[this.props.position].texts.replace('#','0x')
        sprite._knot_data = this.props.data[i];
        sprite.interactive = true;
        sprite.buttonMode = true;
        particleContainers[groupID].addChild(sprite);
        this.props.data[i].index = particleContainers[groupID].children.length-1;        
      } else {
        console.warn(this.props.data[i].id, "not found in spritesheet");
      }
    }
    this.setState({loaded:true}, this.updateVisuals());
  } 

  /**
   *
   * @param {string} id
   * @param {string} dpi - @1x or @2x or @3x etc.
   * @return {boolean}
   */
  contains(id, dpi='@2x') {
    return "node-" + id + dpi + ".png" in this.info;
  }
  /**
   *
   * @param {string} id
   * @param {string} dpi - @1x or @2x or @3x etc.
   * @return {x:number, y:number, w:number, h:number}
   */
  getUV(id, dpi='@2x') {
    return this.atlasesIndexes[this.info["node-" + id + dpi + ".png"] - 1].frames[
      "node-" + id + dpi + ".png"
    ].frame;
  }

  getGroup(id, dpi='@2x') {
    return this.info["node-" + id + dpi + ".png"] - 1;
  }

  getSprite(id){
    const id_group = this.getGroup(id);
    const element = this.props.data.find(d=>d.id===id);
    const i = this.props.data.indexOf(element);
    const index = this.props.data[i].index;
    return particleContainers[id_group].children[index];
  }

  updateVisuals(doZoom){
    // console.log('update visuals');
    graphics.clear();

    let to_show = this.props.data.filter(d=> this.props.filter.indexOf(d.id)!==-1 && this.props.openedKnots.indexOf(d.id)===-1 );
    let to_fade = this.props.data.filter(d=> this.props.filter.indexOf(d.id)===-1 && this.props.openedKnots.indexOf(d.id)===-1 );    
    let to_hide = this.props.openedKnots.map(d=> this.props.data.find(dd=>dd.id===d) );
    
    const zoomTo = to_hide[to_hide.length-1];

    to_show = to_show.map(d=>d.id);
    to_fade = to_fade.map(d=>d.id);
    to_hide = to_hide.map(d=>d.id);

    particleContainers.forEach(container=>{
      container.children.forEach(child=>{
        if (child.isSprite) {
          if (to_show.indexOf(child._knot_data.id)!==-1) {
            child.visible = true;
            child.alpha = 1;
            graphics.lineStyle(0.5, Utilities.colors[this.props.position].lines.replace("#", "0x"), 1, 0.5, true);
            drawLine(graphics, child._knot_data);
          }
          else if (to_fade.indexOf(child._knot_data.id)!==-1) {
            child.visible = true;
            child.alpha = 0.3;
            graphics.lineStyle(0.5, Utilities.colors[this.props.position].lines.replace("#", "0x"), 0.3, 0.5, true);
            drawLine(graphics, child._knot_data);
          }
          else if (to_hide.indexOf(child._knot_data.id)!==-1) {
            // child.visible = false;
            graphics.lineStyle(0.5, Utilities.colors[this.props.position].lines.replace("#", "0x"), 1, 0.5, true);
            drawLine(graphics, child._knot_data);
          }          
          else {
            console.warn(child._knot_data.id, 'is not included.')
          }

        } else {
          console.warn('Not a sprite:', child);
        }
      });
    });


    if (zoomTo && doZoom && this.props.autoZoom){
      const x = zoomTo.x+window.innerWidth/2;
      const y = zoomTo.y+window.innerHeight/2;

      viewport.snapZoom({
        width: window.innerWidth/2,
        time: 1000,
        ease: 'easeOutSine',
        removeOnComplete: true,
        removeOnInterrupt: true
      })
      viewport.snap(x, y,{
        time: 1000,
        ease: 'easeOutSine',
        removeOnComplete: true,
        removeOnInterrupt: true
      })

    }
    
  }

  repositionVisuals(){
    if (simulation.alpha()>0.01) {
      requestAnimationFrame(this.repositionVisuals);
    } 
    graphics.clear();
    particleContainers.forEach(container=>{
      container.children.forEach(child=>{
        if (child.isSprite) {
          child.position.set(child._knot_data.x, child._knot_data.y);
          child.tint = Utilities.colors[this.props.position].texts.replace("#", "0x");

          if ( this.props.filter.indexOf(child._knot_data.id)!==-1 && this.props.openedKnots.indexOf(child._knot_data.id)===-1 ) {
            graphics.lineStyle(0.5, Utilities.colors[this.props.position].lines.replace("#", "0x"), 1, 0.5, false);
            drawLine(graphics, child._knot_data);

          } else if ( this.props.filter.indexOf(child._knot_data.id)===-1 && this.props.openedKnots.indexOf(child._knot_data.id)===-1 ) {
            graphics.lineStyle(0.5, Utilities.colors[this.props.position].lines.replace("#", "0x"), 0.3, 0.5, false);
            drawLine(graphics, child._knot_data);
          }
        }
      })
    })

    
    
  }

  highlightVisuals(clipPlaying){
    if (!clipPlaying) return;

    const id = clipPlaying.url.replace('./audio/','').replace('-mp3.mp3','');
    const element = this.props.data.find(d=>d.id===id);

    let alpha = 2;
    let r = JSON.parse(JSON.stringify(element.r));

    const highlight = new PIXI.Graphics();
    highlight.setTransform(window.innerWidth/2, window.innerHeight/2);
    viewport.addChild(highlight);
    
    const doHighlight = () => {
      highlight.clear();
      highlight.lineStyle(0.5, 0xe74c3c, alpha>1?1:alpha, 0.5, true);  
      highlight.drawCircle(element.x, element.y, r);

      alpha -= 0.005;
      r += 1;

      if (alpha>0) {
        requestAnimationFrame(doHighlight);
      } else {
        highlight.parent.removeChild(highlight);
      }
    }
    doHighlight();

  }

  componentDidMount(prevProps,prevState){
    // console.log('mounted pixi 2');

    pixiApp = new PIXI.Application({
      width: window.innerWidth,
      height: window.innerHeight,
      backgroundColor: Utilities.colors[this.props.position].background.replace("#", "0x"),
      transparent: false,
      antialias: true,
      resolution: dpi,
      autoResize: true,
    });

    if (!this._rootNode.children.length) {
      this._rootNode.append(pixiApp.view);
    }
    
    viewport = new Viewport({
      center: new PIXI.Point(-window.innerWidth/2, -window.innerHeight/2),
      passiveWheel: false,
      stopPropagation: true,
      divWheel: this._rootNode,
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      worldWidth: window.innerWidth,
      worldHeight: window.innerHeight,
      interaction: pixiApp.renderer.plugins.interaction // the interaction module is important for wheel to work properly when renderer.view is placed or scaled
    });

    const elms_in_view = (the_viewport) => {
      const t_k = the_viewport.transform.scale._x;
      const t_x = the_viewport.transform.position._x;
      const t_y = the_viewport.transform.position._y;    
              
      let elementsInView = this.props.data.filter(d=>{
        d.x_transformed = t_x + t_k*d.x;
        d.y_transformed = t_y + t_k*d.y;

        const a = d.x_transformed - -window.innerWidth*t_k/2 - window.innerWidth/2;
        const b = d.y_transformed - -window.innerHeight*t_k/2 - window.innerHeight/2;
        const c = a*a + b*b;
        // assign to data
        d.square_of_distance_from_center = c;
        const xInView = d.x_transformed > -window.innerWidth*t_k/2 && d.x_transformed < (window.innerWidth-window.innerWidth/2*t_k);
        const yInView = d.y_transformed > -window.innerHeight*t_k/2 && d.y_transformed < (window.innerHeight-window.innerHeight/2*t_k);
        return (xInView && yInView);
      });

      elementsInView = elementsInView.sort((a,b)=>(a.square_of_distance_from_center-b.square_of_distance_from_center));
      elementsInView = elementsInView.map(d=>d.id);

      this.props.onZoom(elementsInView, { k: t_k, x: t_x, y: t_y });
    }

    viewport
      // .moveCenter(window.innerWidth/2,window.innerHeight/2)
      .clampZoom(Utilities.zoomOptions)
      .drag({pressDrag:true, clampWheel:true})
      .pinch()
      .wheel()
      .on("moved", e => {
        const t_k = e.viewport.transform.scale._x;
        const t_x = e.viewport.transform.position._x;
        const t_y = e.viewport.transform.position._y;
        this.props.onZoom(null, { k: t_k, x: t_x, y: t_y });
      })
      .on("moved-end", elms_in_view)
      .on('clicked', e => {
        let clicked = this.props.data.filter(d=>{
          const delta_x = e.world.x - window.innerWidth/2 - d.x;
          const delta_y = e.world.y - window.innerHeight/2 - d.y;
          // console.log(delta_x, delta_y);
          // console.log(delta_x*delta_x + delta_y*delta_y);
          // console.log(d.r*d.r);
          return (delta_x*delta_x + delta_y*delta_y) < d.r*d.r
        })
        // console.log(clicked)

        clicked.forEach(d=>this.props.onOpenKnot(d.id))

      });
    
    elms_in_view(viewport);

    // add the viewport to the stage
    pixiApp.stage.addChild(viewport);

    graphics = new PIXI.Graphics();
    graphics.lineStyle(0.5, Utilities.colors[this.props.position].lines.replace("#", "0x"), 1, 0.5, true);
    graphics.setTransform(window.innerWidth/2, window.innerHeight/2);
    // add graphics to the viewport
    viewport.addChild(graphics);

    if (Object.keys(PIXI.Loader.shared.resources).length!==atlases_amount) {
      // list of JSONs describing coordinates of textures in atlases (spritesheets-*.png)
      this.atlasesIndexes = [];
      // list containing actual textures
      this.atlas = [];
      // in which container we store elements
      this.info = [];
      this.loadData();
    }
  }

  componentDidUpdate(prevProps,prevState){
    // console.log('updated pixi 2');
    if (this.state.loaded){
      if (prevProps.voicePlaying !== this.props.voicePlaying || prevProps.play !== this.props.play) {
        // Since we move to random reproduction, don't highlight visuals
        // this.highlightVisuals(this.props.voicePlaying);
      }
      if (prevProps.filter !== this.props.filter || prevProps.openedKnots !== this.props.openedKnots) {
        this.updateVisuals(this.props.openedKnots.length > prevProps.openedKnots.length);
      } else if (prevProps.position !== this.props.position) {
        simulation.nodes(this.props.data);
        simulation.force('x').x(d=>d[`mds_${this.props.position}_x`]);
        simulation.force('y').y(d=>d[`mds_${this.props.position}_y`]);
        simulation.on("end", () => {
          console.log('simulation ended for', this.props.position);
        });
        simulation.alpha(1)
        simulation.restart();

        pixiApp.renderer.backgroundColor = Utilities.colors[this.props.position].background.replace("#", "0x");
        this.repositionVisuals();

        if (this.props.openedKnots.length > 0) {
          let to_hide = this.props.openedKnots.map(d=> this.props.data.find(dd=>dd.id===d) );
          const zoomTo = to_hide[to_hide.length-1];
          console.log(zoomTo);
          const x = zoomTo[`mds_${this.props.position}_x`]+window.innerWidth/2;
          const y = zoomTo[`mds_${this.props.position}_y`]+window.innerHeight/2;
          console.log(x,y);
          setTimeout(()=>{
            viewport.snap(x, y,{
              time: 1000,
              ease: 'easeOutSine',
              removeOnComplete: true,
              removeOnInterrupt: true
            });
          }, 300);
        }

      } else if (prevProps.zoomLevel !== this.props.zoomLevel) {
        viewport.setZoom(this.props.zoomLevel, true)
      }
    }
  }

  componentWillUnmount(prevProps,prevState){

  }

  _setRef(componentNode) {
    this._rootNode = componentNode;
  }
  
  render() {
    const elmStyle = {
      width: "100vw",
      height: "100vh",
      position: "absolute",
      top: 0
    };
  return <div className="pixi-container" style={elmStyle} ref={this._setRef.bind(this)}></div>;
  }
}

export default KnotsPixi2;


let countercolor = 0;
/**
 *
 * @param {PIXI.Graphics} graphics
 * @param {object} elementData
 */
function drawLine(graphics, elementData) {

  // const this_colors = [ 0xff0000, 0x0000ff ]

  const moveTo = elementData.bezier_instructions.find(d=>d.command==='M');
  const bezierTo_s = elementData.bezier_instructions.filter(d=>d.command==='C');

  // graphics.lineStyle(1, this_colors[countercolor%2], 1, 0.5, false);
  // countercolor++;

  graphics.moveTo(
    +moveTo.x + elementData.x,
    +moveTo.y + elementData.y
  );
  bezierTo_s.forEach(b=>{
    graphics.bezierCurveTo(+b.x1 + elementData.x, +b.y1 + elementData.y, +b.x2 + elementData.x, +b.y2 + elementData.y, +b.x + elementData.x, +b.y + elementData.y);
  });
}


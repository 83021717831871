import React from 'react';
import VisualizationView from './VisualizationView';
import HomePage from './HomePage';
import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import './App.css';

const site = {
  title: "Tied in (k)nots"
}

function App() {
  return (
  <Router basename="/">
    <Switch>
      <Route path="/visualization">
        <VisualizationView title={site.title}/>
      </Route>
      <Route path="/">
        <HomePage />
      </Route>
    </Switch>
  </Router>
  );
}

export default App;

import React, { Component } from "react";
import * as d3 from "d3";
import PIXI from "pixi-sound";
import Utilities from "../Utilities";

// import Header from '../Header';
import Loading from "../Loading";
// import KnotsViz from "../KnotsViz";
import IndividualKnot from "../IndividualKnot";
import KnotInfo from "../KnotInfo";
// import Voices from "../Voices";
import RandomAudio from "../RandomAudio";
import VisualizationTools from "../VisualizationTools";
// import RenderApp from "../PixiKnotz";
import KnotsPixi2 from "../KnotsPixi2";
import Annotations from "../Annotations";

// import { Simulate } from 'react-dom/test-utils';

const clips_urls = [
  "R0155-mp3.mp3",
  "R0001-mp3.mp3",
  "R0051-mp3.mp3",
  "R0055-mp3.mp3",
  "R0101-mp3.mp3",
  "R0105-mp3.mp3",
  "R0201-mp3.mp3",
  "R0251-mp3.mp3",
  "R0255-mp3.mp3",
  "R0301-mp3.mp3",
  "R0305-mp3.mp3",
  "R0351-mp3.mp3",
  "R0355-mp3.mp3",
  "R0401-mp3.mp3",
  "R0405-mp3.mp3",
  "R0451-mp3.mp3",
  "R0455-mp3.mp3",
  "R0551-mp3.mp3",
  "R0555-mp3.mp3",
  "R0601-mp3.mp3",
  "R0605-mp3.mp3",
  "R0651-mp3.mp3",
  "R0701-mp3.mp3",
  "R0705-mp3.mp3",
  "R0751-mp3.mp3",
  "R0755-mp3.mp3",
  "R0802-mp3.mp3",
  "R0852-mp3.mp3",
  "R0855-mp3.mp3",
  "R0902-mp3.mp3",
  "R0905-mp3.mp3",
  "R0952-mp3.mp3",
  "R0955-mp3.mp3",
  "R1002-mp3.mp3",
  "R1052-mp3.mp3",
  "R1055-mp3.mp3",
  "R1105-mp3.mp3",
  "R1152-mp3.mp3",
  "R1155-mp3.mp3",
  "R1202-mp3.mp3",
  "R1205-mp3.mp3",
  "R1252-mp3.mp3",
  "R1255-mp3.mp3",
  "R1305-mp3.mp3",
  "R1352-mp3.mp3",
  "R1402-mp3.mp3",
  "R1405-mp3.mp3",
  "R1452-mp3.mp3",
  "R1455-mp3.mp3",
  "R1502-mp3.mp3",
  "R1505-mp3.mp3",
  "R1552-mp3.mp3",
  "R1602-mp3.mp3",
  "R1605-mp3.mp3",
  "R1655-mp3.mp3",
  "R1702-mp3.mp3",
  "R1705-mp3.mp3",
  "R1752-mp3.mp3",
  "R1755-mp3.mp3",
  "R1802-mp3.mp3",
  "R1805-mp3.mp3",
  "R1855-mp3.mp3",
  "R1902-mp3.mp3",
  "R1952-mp3.mp3",
  "R1955-mp3.mp3",
  "R2002-mp3.mp3",
  "R2005-mp3.mp3",
  "R2052-mp3.mp3",
  "R2055-mp3.mp3",
  "R2102-mp3.mp3",
  "R2152-mp3.mp3",
  "R2155-mp3.mp3",
  "R2205-mp3.mp3",
  "R2252-mp3.mp3",
  "R2255-mp3.mp3",
  "R2302-mp3.mp3",
  "R2305-mp3.mp3",
  "R2352-mp3.mp3",
  "R2355-mp3.mp3",
  "R2405-mp3.mp3",
];
// console.log("clips available:", clips_urls.length);
let links = {
  links_incident: [],
  links_victim: [],
  links_harasser: [],
};
class VisualizationView extends Component {
  constructor(props) {
    super(props);

    this.loadData = this.loadData.bind(this);
    this.loadCompleteData = this.loadCompleteData.bind(this);
    this.loadVoices = this.loadVoices.bind(this);
    this.visualizationZoomed = this.visualizationZoomed.bind(this);
    this.setVoicePlaying = this.setVoicePlaying.bind(this);
    this.repositionFunction = this.repositionFunction.bind(this);
    this.playFunction = this.playFunction.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.onOpenKnot = this.onOpenKnot.bind(this);
    this.onCloseIndividualKnot = this.onCloseIndividualKnot.bind(this);
    this.onSliderZoom = this.onSliderZoom.bind(this);
    this.onChangeAutoZoom = this.onChangeAutoZoom.bind(this);
    this.onToggleAnnotations = this.onToggleAnnotations.bind(this);

    this.state = {
      // if true it loads "data/tied in nots - data - coded.csv" and run force layout with anticollision
      // usefull to calculate spatialized positions and downlaod the JSON with complete data
      // if false or commented out it load the complete JSON

      // devMode: true,
      // showFewElements:[0,15],

      loading_data: true,
      loading_audio: true,
      elements_position: "incident",
      play: false,
      in_view: [],
      not_in_view: [],
      clips_library: {},
      voice_playing: undefined,
      opened_knots_ids: [],
      transform: { k: 1, x: 0, y: 0 },
      simulationIsRunning: false,
      zoomLevel: 1,
      autoZoom: true,
      annotations_visible: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const reports_url = "data/tied in nots - data - reports.csv";

    if (this.state.devMode) {
      const data_url = "data/tied in nots - data - coded.csv";
      const data_url_many = "data/tied in nots - data - coded.csv";
      this.loadData(data_url, true, reports_url);
    } else {
      const data_url_positions = "data/tied in nots - knots spatialized.json";
      this.loadCompleteData(data_url_positions, reports_url);
    }

    this.loadVoices(clips_urls);
  }

  loadCompleteData(data_url, reports_url) {
    d3.json(data_url).then((data) => {
      // data = data.slice(0,1)
      d3.csv(reports_url).then((reports) => {
        this.setState({
          data: data,
          filter: data.map((d) => d.id),
          reports: reports,
          loading_data: false,
        });
      });
    });
  }

  loadData(data_url, devMode, reports_url) {
    let data_promise;
    if (devMode) {
      data_promise = d3.csv(data_url);
    } else {
      data_promise = d3.json(data_url);
    }
    data_promise.then((data) => {
      const links_incident = [];
      const links_victim = [];
      const links_harasser = [];

      data = data.filter(
        (d) =>
          d.done === "TRUE" && d.skip !== "TRUE" && d.relevant_line_1.length > 0
      );
      if (this.state.showFewElements) {
        data = data.slice(
          this.state.showFewElements[0],
          this.state.showFewElements[1]
        );
      }

      for (let i = 0; i < data.length; i++) {
        delete data[i]["TODO"];
        delete data[i]["done"];
        delete data[i]["skip"];

        const line = data[i].line_with_not
          ? data[i].line_with_not
          : data[i].relevant_line_1;

        let knot = [];
        let random_variations = [];
        const rv_max = 0.35;
        const rv_min = -rv_max;
        if (data[i].type_of_abuse !== "") {
          let type_of_abuse = Utilities.knots_grid_property_value(
            "type_of_abuse",
            data[i].type_of_abuse.toLowerCase()
          );
          if (type_of_abuse) {
            knot.push(type_of_abuse.number);
            random_variations.push(d3.randomNormal(rv_min, rv_max)());
          }
        }
        if (data[i].hierarchy !== "") {
          let hierarchy = Utilities.knots_grid_property_value(
            "hierarchy",
            data[i].hierarchy.toLowerCase()
          );
          if (hierarchy) {
            knot.push(hierarchy.number);
            random_variations.push(d3.randomNormal()(rv_min, rv_max));
          }
        }
        if (data[i].time !== "") {
          let time = Utilities.knots_grid_property_value(
            "time",
            data[i].time.toLowerCase()
          );
          if (time) {
            knot.push(time.number);
            random_variations.push(d3.randomNormal()(rv_min, rv_max));
          }
        }
        if (data[i].at_conference !== "") {
          let at_conference = Utilities.knots_grid_property_value(
            "at_conference",
            data[i].at_conference
          );
          if (at_conference) {
            knot.push(at_conference.number);
            random_variations.push(d3.randomNormal()(rv_min, rv_max));
          }
        }
        if (data[i].incident_reported !== "") {
          let incident_reported = Utilities.knots_grid_property_value(
            "incident_reported",
            data[i].incident_reported
          );
          if (incident_reported) {
            knot.push(incident_reported.number);
            random_variations.push(d3.randomNormal()(rv_min, rv_max));
          }
        }
        if (data[i].successful_report !== "") {
          let successful_report = Utilities.knots_grid_property_value(
            "successful_report",
            data[i].successful_report
          );
          if (successful_report) {
            knot.push(successful_report.number);
            random_variations.push(d3.randomNormal()(rv_min, rv_max));
          }
        }
        if (data[i].stayed_in_academia !== "") {
          let stayed_in_academia = Utilities.knots_grid_property_value(
            "stayed_in_academia",
            data[i].stayed_in_academia
          );
          if (stayed_in_academia) {
            knot.push(stayed_in_academia.number);
            random_variations.push(d3.randomNormal()(rv_min, rv_max));
          }
        }
        if (data[i].avoided_people_or_places !== "") {
          let avoided_people_or_places = Utilities.knots_grid_property_value(
            "avoided_people_or_places",
            data[i].avoided_people_or_places
          );
          if (avoided_people_or_places) {
            knot.push(avoided_people_or_places.number);
            random_variations.push(d3.randomNormal()(rv_min, rv_max));
          }
        }

        const scale_factor = 3000;

        data[i] = {
          id: data[i].id,
          line: line,

          type_of_abuse: data[i].type_of_abuse.toLowerCase(),
          hierarchy: data[i].hierarchy.toLowerCase(),
          time: data[i].time.toLowerCase(),
          at_conference: data[i].at_conference,
          incident_reported: data[i].incident_reported,
          successful_report: data[i].successful_report,
          stayed_in_academia: data[i].stayed_in_academia,
          avoided_people_or_places: data[i].avoided_people_or_places,

          victim_hierarchy_0: data[i].victim_hierarchy_0,
          victim_hierarchy_1: data[i].victim_hierarchy_1,
          victim_hierarchy_2: data[i].victim_hierarchy_2,
          victim_task: data[i].victim_task,
          victim_office: data[i].victim_office,

          harasser_hierarchy_0: data[i].harasser_hierarchy_0,
          harasser_hierarchy_1: data[i].harasser_hierarchy_1,
          harasser_hierarchy_2: data[i].harasser_hierarchy_2,
          harasser_task: data[i].harasser_task,
          harasser_office: data[i].harasser_office,

          mds_incident_x: +data[i]["mds_incident_x"] * scale_factor,
          mds_incident_y: +data[i]["mds_incident_y"] * scale_factor,
          mds_victim_x: +data[i]["mds_victim_x"] * scale_factor,
          mds_victim_y: +data[i]["mds_victim_y"] * scale_factor,
          mds_harasser_x: +data[i]["mds_harasser_x"] * scale_factor,
          mds_harasser_y: +data[i]["mds_harasser_y"] * scale_factor,
          x: +data[i]["mds_incident_x"] * scale_factor,
          y: +data[i]["mds_incident_y"] * scale_factor,

          knot: knot,
          // 'random_variations': random_variations
        };

        const identical_incidents = data.slice(0, i).filter((dd) => {
          return (
            data[i].type_of_abuse === dd.type_of_abuse &&
            data[i].hierarchy === dd.hierarchy &&
            data[i].time === dd.time &&
            data[i].at_conference === dd.at_conference &&
            data[i].incident_reported === dd.incident_reported &&
            data[i].successful_report === dd.successful_report &&
            data[i].stayed_in_academia === dd.stayed_in_academia &&
            data[i].avoided_people_or_places === dd.avoided_people_or_places
          );
        });
        identical_incidents.forEach((identical_incident) => {
          links_incident.push({
            source: identical_incident.id,
            target: data[i].id,
          });
        });
        const identical_victims = data.slice(0, i).filter((dd) => {
          return (
            data[i].victim_hierarchy_0 === dd.victim_hierarchy_0 &&
            data[i].victim_hierarchy_1 === dd.victim_hierarchy_1 &&
            data[i].victim_hierarchy_2 === dd.victim_hierarchy_2 &&
            data[i].victim_task === dd.victim_task &&
            data[i].victim_office === dd.victim_office
          );
        });
        identical_victims.forEach((identical_victim) => {
          links_victim.push({
            source: identical_victim.id,
            target: data[i].id,
          });
        });

        const identical_harassers = data.slice(0, i).filter((dd) => {
          return (
            data[i].harasser_hierarchy_0 === dd.harasser_hierarchy_0 &&
            data[i].harasser_hierarchy_1 === dd.harasser_hierarchy_1 &&
            data[i].harasser_hierarchy_2 === dd.harasser_hierarchy_2 &&
            data[i].harasser_task === dd.harasser_task &&
            data[i].harasser_office === dd.harasser_office
          );
        });
        identical_harassers.forEach((identical_harasser) => {
          links_harasser.push({
            source: identical_harasser.id,
            target: data[i].id,
          });
        });
      }
      links = {
        links_incident: links_incident,
        links_victim: links_victim,
        links_harasser: links_harasser,
      };
      // console.log(links);
      d3.csv(reports_url).then((reports) => {
        this.setState({
          data: data,
          links: links,
          filter: data.map((d) => d.id),
          reports: reports,
          loading_data: false,
        });
      });
    });
  }

  loadVoices(voices_urls) {
    const clips_library = {};
    voices_urls.forEach((clip) => {
      PIXI.sound.Sound.from({
        url: "./audio/" + clip,
        preload: true,
        loaded: (err, sound) => {
          const clip_id = clip.substring(0, 5);
          // console.log('loading', clip_id)
          clips_library[clip_id] = sound;
          if (Object.keys(clips_library).length === voices_urls.length) {
            // console.log(voices_urls.length,clips_library)
            this.setState({
              loading_audio: false,
              clips_library: clips_library,
            });
          }
        },
      });
    });
  }

  setVoicePlaying(id) {
    // console.log("Playing:", id)
    this.setState({ voice_playing: id });
  }

  visualizationZoomed(ids, transformation) {
    // ids are ordered depending on the distance from the viewport center
    if (ids) {
      this.setState({ in_view: ids });
      const not_in_view = this.state.data
        .map((d) => d.id)
        .filter((d) => ids.indexOf(d) < 0);
      this.setState({ not_in_view: not_in_view });
    }
    if (transformation) {
      this.setState({
        transform: {
          k: transformation.k,
          x: transformation.x,
          y: transformation.y,
        },
        zoomLevel: transformation.k,
      });
      // d3.select('.knots-info-and-cards').style("transform", transform)
    }
  }

  repositionFunction(option) {
    this.setState({ elements_position: option, simulationIsRunning: true });
  }

  playFunction() {
    this.setState({ play: !this.state.play });
  }

  onFilter(active_filters) {
    // console.log(active_filters)
    let ids = this.state.data.map((d) => d.id);
    active_filters.forEach((f) => {
      f.value = f.value.replace(/X/g, " ");
      let filtered = this.state.data
        .filter((d) => d[f.property] === f.value)
        .map((d) => d.id);
      ids = ids.filter((id) => filtered.indexOf(id) > -1);
    });
    // console.log('Elements not filtered:', ids);
    this.setState({ filter: ids });
  }

  onOpenKnot(knot_id) {
    console.log("opening", knot_id);

    // console.log(this.state.data.find(d=>d.id===knot_id))
    if (this.state.opened_knots_ids.indexOf(knot_id) === -1) {
      this.setState((prevState) => ({
        opened_knots_ids: prevState.opened_knots_ids.concat([knot_id]),
      }));
    } else {
      console.warn(knot_id, "already exists in the array of opened knots ids");
    }
  }

  onCloseIndividualKnot(knot_id) {
    console.log("closing", knot_id);
    this.setState((prevState) => ({
      opened_knots_ids: prevState.opened_knots_ids.filter(
        (id) => id !== knot_id
      ),
    }));
  }

  onSliderZoom(newZoom) {
    this.setState({ zoomLevel: newZoom });
  }

  onChangeAutoZoom(newValue) {
    this.setState({ autoZoom: newValue });
  }

  onToggleAnnotations() {
    this.setState({ annotations_visible: !this.state.annotations_visible });
  }

  render() {
    const style_knots_into_and_card = {
      transform: `translate(${this.state.transform.x}px, ${this.state.transform.y}px) scale(${this.state.transform.k}, ${this.state.transform.k})`,
    };
    return (
      <div className={`main ${this.state.elements_position}`}>
        {/* <Header title={this.props.title}/> */}
        <div style={{ width: "100vw", height: "100vh", overflow: "hidden" }}>
          {this.state.loading_data && <Loading />}
          {!this.state.loading_data && (
            <KnotsPixi2
              position={this.state.elements_position}
              onZoom={this.visualizationZoomed}
              zoomLevel={this.state.zoomLevel}
              autoZoom={this.state.autoZoom}
              data={this.state.data}
              onOpenKnot={this.onOpenKnot}
              filter={this.state.filter}
              openedKnots={this.state.opened_knots_ids}
              voicePlaying={this.state.clips_library[this.state.voice_playing]}
              play={this.state.play}
            />
          )}
          {this.state.annotations_visible && (
            <Annotations
              visible={this.state.annotations_visible}
              transform={this.state.transform}
              position={this.state.elements_position}
            />
          )}
          {!this.state.loading_data && (
            <div
              className="knots-info-and-cards noselect"
              style={style_knots_into_and_card}
            >
              <div
                className="g-master"
                style={{
                  transform: `translate(${window.innerWidth / 2}px, ${
                    window.innerHeight / 2
                  }px)`,
                }}
              >
                <div
                  className="for-test-purposes"
                  style={style_test_rect}
                ></div>
                {this.state.opened_knots_ids.map((d, i) => {
                  return (
                    <div
                      id={"wrapper-opened-svg-" + d}
                      key={"wrapper-opened-svg-" + d}
                    >
                      <IndividualKnot
                        key={"opened-svg-" + d}
                        onClose={this.onCloseIndividualKnot}
                        data={this.state.data.find((dd) => dd.id === d)}
                        position={this.state.elements_position}
                        visualizationTransformation={this.state.transform}
                      />
                      <KnotInfo
                        key={"opened-" + d}
                        className={"opened-" + d}
                        data={this.state.data.find((dd) => dd.id === d)}
                        report={this.state.reports.find((dd) => dd.id === d)}
                        position={this.state.elements_position}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          { (!this.state.loading_audio && !this.state.loading_data) && (
            // <Voices
            //   clipsLibrary={this.state.clips_library}
            //   inView={this.state.in_view}
            //   notInView={this.state.not_in_view}
            //   onVoicePlaying={this.setVoicePlaying}
            //   play={this.state.play}
            // />
            <RandomAudio
              play={this.state.play}
              clipsLibrary={this.state.clips_library}
              transform={this.state.transform}
              data={this.state.data}
              position={this.state.elements_position}
            />
          )}
        </div>
        <VisualizationTools
          repositionFunction={this.repositionFunction}
          playFunction={this.playFunction}
          play={this.state.play}
          filter={this.state.filter}
          onFilter={this.onFilter}
          onSliderZoom={this.onSliderZoom}
          zoomLevel={this.state.zoomLevel}
          autoZoom={this.state.autoZoom}
          onChangeAutoZoom={this.onChangeAutoZoom}
          onToggleAnnotations={this.onToggleAnnotations}
        />
      </div>
    );
  }
}

export default VisualizationView;

const style_test_rect = {
  position: "absolute",
  width: "10px",
  height: "10px",
  display: "block",
  background: "black",
  top: -5,
  left: -5,
};

import React, { Component } from "react";
import * as d3 from "d3";
import * as d3_ from "d3-svg-annotation";

const type = d3_.annotationCalloutCircle;

const makeAnnotations = d3_
  .annotation()
  .editMode(false)
  .notePadding(5)
  .type(type)
  .annotations([]);

window.the_global_annotations = makeAnnotations;

const template = {
  position: "victim",
  x: 0,
  y: 0,
  dx: 100,
  dy: 100,
  connector: { points: [[80, 20.5]] },
  subject: { radius: 50, radiusPadding: 10 },
  note: { label: "Graduate students" },
};

class Annotations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      annotations_victim: [
        {
          x: -732.6611585617065,
          y: -54.963662177324295,
          dx: 299.5522155761719,
          dy: 300.3828525543213,
          connector: { points: [[80, 20.5]] },
          subject: { radius: 344.6460527138728, radiusPadding: 10 },
          note: { label: "Graduate students" },
        },
        {
          x: -233.96312284469604,
          y: -740.243945479393,
          dx: 137.3929214477539,
          dy: 123.96868896484375,
          connector: { points: [[80, 20.5]] },
          subject: { radius: 117.80201957873368, radiusPadding: 10 },
          note: { label: "Master students" },
        },
        {
          x: -1152.6386036276817,
          y: -915.5499841272831,
          dx: -278.6627197265625,
          dy: -229.45562744140625,
          connector: { points: [[80, 20.5]] },
          subject: { radius: 292.50819193668457, radiusPadding: 10 },
          note: { label: "PhD students" },
        },
        {
          x: -1590.0899715423584,
          y: -177.45234084129333,
          dx: -134.36911010742188,
          dy: 128.99268531799316,
          connector: { points: [[80, 20.5]] },
          subject: { radius: 132.014228215624, radiusPadding: 10 },
          note: { label: "Master students (lowercase 😅)" },
        },
        {
          x: -320.6974313855171,
          y: 1813.4877734184265,
          dx: -243.97681140899658,
          dy: -225.26511573791504,
          connector: { points: [[80, 20.5]] },
          subject: { radius: 252.11341152062337, radiusPadding: 10 },
          note: { label: "Undergraduate students" },
        },
        {
          x: -92.35263741016388,
          y: 808.6915932297707,
          dx: 182.24180603027344,
          dy: 128.2706069946289,
          connector: { points: [[80, 20.5]] },
          subject: { radius: 149.95173374070697, radiusPadding: 10 },
          note: { label: "Students (no further info reported)" },
        },
        {
          x: 2665.604650575615,
          y: -537.4257979552649,
          dx: 194.99301727483345,
          dy: 159.49519203496942,
          connector: { points: [[80, 20.5]] },
          subject: { radius: 169.13713462044336, radiusPadding: 10 },
          note: { label: "Untenured Assistant Professors" },
        },
        {
          x: 2684.4793085939727,
          y: -29.729546738171962,
          dx: 124.96050976584763,
          dy: 91.34987525889835,
          connector: { points: [[80, 20.5]] },
          subject: { radius: 97.19972291118499, radiusPadding: 10 },
          note: { label: "Visiting Assistant Professors" },
        },
        {
          x: 2344.1711539356093,
          y: 180.15066425820189,
          dx: 121.41297522467175,
          dy: 115.19731037636595,
          connector: { points: [[80, 20.5]] },
          subject: { radius: 89.8286180910915, radiusPadding: 10 },
          note: { label: "Postdocs" },
        },
        {
          x: 2197.4351714119166,
          y: -638.2136905315582,
          dx: -126.00843210370863,
          dy: -126.54297519952695,
          connector: { points: [[80, 20.5]] },
          subject: { radius: 115.87521402172234, radiusPadding: 10 },
          note: { label: "Adjunct Professors" },
        },
        {
          x: 2121.6611404502555,
          y: -249.84140610405444,
          dx: -137.8041915809947,
          dy: -126.06491684624194,
          connector: { points: [[80, 20.5]] },
          subject: { radius: 125.16168743001526, radiusPadding: 10 },
          note: { label: "Untenured Faculty" },
        },
        {
          x: 1072.1683987986478,
          y: 720.2979312345442,
          dx: 176.2933987986478,
          dy: 125.56282381266925,
          connector: { points: [[80, 20.5]] },
          subject: { radius: 126.58297896553988, radiusPadding: 10 },
          note: { label: "Workers" },
        },
      ],
      annotations_incident: [],
      annotations_harasser: [
        // {
        //   x: -909.4239943965077,
        //   y: -1976.9742115485205,
        //   dx: 138.71357884567976,
        //   dy: -87.2018116461768,
        //   connector: { points: [[80, 20.5]] },
        //   subject: { radius: 50, radiusPadding: 10 },
        //   note: { label: "😅 mistake" },
        // },
        {
          x: 921.694964295393,
          y: -627.5970284271896,
          dx: -534.1202632131519,
          dy: 480.88156349175574,
          connector: { points: [[80, 20.5]] },
          subject: { radius: 568.0655984364867, radiusPadding: 10 },
          note: { label: "All Tenured Professors" },
        },
        {
          x: 453.02638099235617,
          y: 754.4472064639394,
          dx: 308.57659766716085,
          dy: 78.11746403229881,
          connector: { points: [[80, 20.5]] },
          subject: { radius: 260.84164216537005, radiusPadding: 10 },
          note: { label: "We only know they were faculty members" },
        },
        {
          x: 6.9194420613562215,
          y: 724.7367454575784,
          dx: -207.5746709070886,
          dy: 158.19705448620388,
          connector: { points: [[80, 20.5]] },
          subject: { radius: 172.80738738730574, radiusPadding: 10 },
          note: { label: "Supervisors, no more specific position reported" },
        },
        {
          x: 309.9287899040405,
          y: 1224.8476026639619,
          dx: -162.84643096693117,
          dy: 131.56580334755574,
          connector: { points: [[80, 20.5]] },
          subject: { radius: 139.34111188791044, radiusPadding: 10 },
          note: { label: "Untenured faculty members" },
        },
        {
          x: 588.550101472943,
          y: 1210.4621317981218,
          dx: 181.40434951981797,
          dy: -155.7939106823469,
          connector: { points: [[80, 20.5]] },
          subject: { radius: 170.77421802034345, radiusPadding: 10 },
          note: { label: "Untenured Faculty Members" },
        },
        {
          x: -2048.8804719659397,
          y: -486.8893247835514,
          dx: 181.3359586981228,
          dy: -131.26340925620764,
          connector: { points: [[80, 20.5]] },
          subject: { radius: 128.32709513017068, radiusPadding: 10 },
          note: { label: "PhD Students" },
        },
        {
          x: -2327.498337442466,
          y: -649.9226854229962,
          dx: -225.09513920027848,
          dy: -166.48365954408996,
          connector: { points: [[80, 20.5]] },
          subject: { radius: 128.32709513017068, radiusPadding: 10 },
          note: { label: "Graduate students" },
        },
        {
          x: -2301.5763526813157,
          y: -388.1112280417839,
          dx: -103.16375502506571,
          dy: 83.96714109884113,
          connector: { points: [[80, 20.5]] },
          subject: { radius: 77.08377794756657, radiusPadding: 10 },
          note: { label: "Graduate Master Students" },
        },
        {
          x: -1870.2494859522665,
          y: -118.30609176244997,
          dx: -140.97885606945397,
          dy: 139.93241264734007,
          connector: { points: [[80, 20.5]] },
          subject: { radius: 143.93368066230133, radiusPadding: 10 },
          note: { label: "Undergraduates" },
        },
        {
          x: -996.073899500597,
          y: 875.747421345374,
          dx: 127.51539493299674,
          dy: 128.09507759537405,
          connector: { points: [[80, 20.5]] },
          subject: { radius: 132.92303025764568, radiusPadding: 10 },
          note: { label: "Cases with more people involved" },
        },
        {
          x: -1022.126878016222,
          y: 634.9692231031866,
          dx: -94.78630184434701,
          dy: -92.3715361741572,
          connector: { points: [[80, 20.5]] },
          subject: { radius: 84.8397691369606, radiusPadding: 10 },
          note: { label: "Workers" },
        },
      ],
    };
  }
  _setRef(componentNode) {
    this._rootNode = componentNode;
  }
  componentDidMount() {
    makeAnnotations.annotations(
      this.state[`annotations_${this.props.position}`]
    );
    d3.select(this._rootNode)
      .select("g.master")
      .select("g.annotation-group")
      .call(makeAnnotations);
    // document.addEventListener('keypress',(e)=>{
    //   if (e.key==='a'){
    //     d3.select(this._rootNode).style('pointer-events','all');
    //     d3.select('.visualization-tools').style('display','none');
    //   } else if (e.key==='A') {
    //     d3.select(this._rootNode).style('pointer-events','none');
    //     d3.select('.visualization-tools').style('display','block');
    //   } else if (e.key==='+') {
    //     console.log('add annotations');
    //     makeAnnotations.update()
    //     let annotations = this.state[`annotations_${this.props.position}`];
    //     template.position = this.props.position;
    //     template.x = -this.props.transform.x
    //     template.y = -this.props.transform.y
    //     template.dx = -this.props.transform.x + 100
    //     template.dy = -this.props.transform.y + 100
    //     annotations.push({...template});
    //     this.setState({annotations:annotations})
    //   }
    // })
  }
  componentDidUpdate() {
    makeAnnotations.annotations(
      this.state[`annotations_${this.props.position}`]
    );
    d3.select(this._rootNode)
      .select("g.master")
      .select("g.annotation-group")
      .call(makeAnnotations);
  }
  render() {
    const style = {
      width: "100%",
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
    };
    const g_master_style = {
      transform: `translate(${this.props.transform.x}px, ${this.props.transform.y}px) scale(${this.props.transform.k})`,
    };
    const g_annotations_style = {
      transform: `translate(${window.innerWidth / 2}px, ${
        window.innerHeight / 2
      }px)`,
    };
    return (
      <svg
        id="annotations-layer"
        className="noselect"
        style={style}
        ref={this._setRef.bind(this)}
      >
        <g className="master" style={g_master_style}>
          <g className="annotation-group" style={g_annotations_style}></g>
        </g>
      </svg>
    );
  }
}

export default Annotations;

import React, { useEffect, useState, useRef } from "react";
import * as d3 from "d3";

let myInterval;
const temp_playing = [];
let circles;

export default function RandomAudio({
  play,
  clipsLibrary,
  transform,
  data,
  position,
}) {
  const [playing, setPlaying] = useState([]);
  const svgEl = useRef();
  const idsArray = Object.keys(clipsLibrary);
  const playRandom = () => {
    const randomID = idsArray[Math.floor(Math.random() * idsArray.length)];
    const clip = clipsLibrary[randomID];
    if (!clip.isPlaying) {
      const instance = clip.play();
      temp_playing.push(randomID);
      setPlaying(JSON.parse(JSON.stringify(temp_playing)));
      instance.on("end", function () {
        const index = temp_playing.indexOf(randomID);
        if (index > -1) {
          temp_playing.splice(index, 1);
          setPlaying(JSON.parse(JSON.stringify(temp_playing)));
        }
      });
    }
  };

  useEffect(() => {
    if (play) {
      myInterval = setInterval(function () {
        playRandom();
      }, 4000);
    } else {
      clearInterval(myInterval);
    }
  }, [play]);

  useEffect(() => {
    circles = d3.select(svgEl.current).selectAll("circles");
  }, []);

  const returnStroke = () => {
    switch (position) {
      case "incident":
        return "var(--var-col-text)";
        break;
      case "victim":
        return "var(--var-col-text)";
        break;
      case "harasser":
        return "var(--var-col-victim)";
        break;
      default:
        return "black";
    }
  };

  useEffect(() => {
    const circlesData = data.filter((d) => playing.indexOf(d.id) !== -1);
    circles = circles.data(circlesData, (d) => d.id);
    circles.exit().remove();
    circles = circles
      .enter()
      .append("circle")
      .classed("voiceover-circle", true)
      .attr("cx", (d) => d[`mds_${position}_x`])
      .attr("cy", (d) => d[`mds_${position}_y`])
      .attr("fill", "none")
      .attr("stroke", returnStroke())
      .attr("r", 0)
      .attr("opacity", 1)
      .call((enter, d) => {
        if (enter.size() > 0) {
          const id = enter.data()[0].id;
          const duration = clipsLibrary[id].duration;
          // console.log(id, duration);
          enter
            .transition()
            .duration(duration * 1000)
            .ease(d3.easeLinear)
            .attr("r", duration * 100)
            .attr("opacity", 0);
        }
      })
      .merge(circles);
  }, [playing]);

  useEffect(() => {
    circles
      .attr("cx", (d) => d[`mds_${position}_x`])
      .attr("cy", (d) => d[`mds_${position}_y`]);
  }, [position]);

  const style = {
    width: "100%",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    top: 0,
  };
  const g_master_style = {
    transform: `translate(${transform.x}px, ${transform.y}px) scale(${transform.k})`,
  };
  const g_circles_style = {
    transform: `translate(${window.innerWidth / 2}px, ${
      window.innerHeight / 2
    }px)`,
  };

  return (
    <svg className="noselect" style={style}>
      <g style={g_master_style}>
        <g ref={svgEl} style={g_circles_style}>
          {/* cicles here with d3 */}
        </g>
      </g>
    </svg>
  );
}

import React, { Component } from 'react';

import Utilities from '../Utilities';

import './KnotInfo.css';

let style_knot_info;

class KnotInfo extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();

    this.toggleFullReport = this.toggleFullReport.bind(this);

    this.state = {
      show_full_report: false
    }
  }

  toggleFullReport() {
    this.setState(prevState=>{
      return {show_full_report:!prevState.show_full_report}
    })
  }
  componentDidMount(){

  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.show_full_report && this.state.show_full_report !== prevState.show_full_report) {
      const excerpt = document.querySelector(`.item.${this.props.data.id} p>span`);
      if (excerpt) {
        const topPos = excerpt.offsetTop;
        document.querySelector(`.item.${this.props.data.id} section.answer`).scrollTop = topPos-50;
      }
    } 
  }
  _setRef(componentNode) {
    this._rootNode = componentNode;
  }

  render() {
    let transformation = `translate(${this.props.data[`mds_${this.props.position}_x`]}px, ${this.props.data[`mds_${this.props.position}_y`]}px) scale(0.5)`;
    style_knot_info = {
      display: "flex",
      flexDirection: "column",
      width:"fit-content",
      transform: transformation,
      transformOrigin: "-50% 24px",
      position: "absolute",
      top: 0,
      left: 0
    }
    return (
            <div className={ this.state.show_full_report ? `item show-full-report ${this.props.data.id}` : `item ${this.props.data.id}` } style={style_knot_info} ref={this.myRef}>
              {/* <div className="properties">
                {Object.keys(properties(this.props.data)).slice(0,4).map((d,i)=>{
                  return <div className="property" key={"property-"+d}>
                    <h6>{labels[d]}</h6>
                    <p>{labels[this.props.data[d]]||"N/A"}</p>
                  </div>
                })}
              </div>
              <div className="properties">
                {Object.keys(properties(this.props.data)).slice(4,8).map((d,i)=>{
                  return <div className="property" key={"property-"+d}>
                    <h6>{labels[d]}</h6>
                    <p>{labels[this.props.data[d]]||"N/A"}</p>
                  </div>
                })}
              </div> */}
              <div style={{justifyContent: "center", flexDirection: 'column', paddingTop: '1rem'}}>
                <button className="btn fade-in" onClick={()=>this.toggleFullReport()}>read full testimony</button>
                {/* <p style={{color:'red'}}>V: {this.props.data.victim_hierarchy_0} - {this.props.data.victim_hierarchy_1} - {this.props.data.victim_hierarchy_2} - {this.props.data.victim_task}  - {this.props.data.victim_office}</p>
                <p style={{color:'red'}}>H: {this.props.data.harasser_hierarchy_0} - {this.props.data.harasser_hierarchy_1}  - {this.props.data.harasser_hierarchy_2}  - {this.props.data.harasser_task}  - {this.props.data.harasser_office}</p> */}
              </div>

              <div className="full-report">
                <header>{this.props.data.line}<span className="close" onClick={()=>this.toggleFullReport()}></span></header>
                {/* <section className="coding">
                  <h4>Curated Coding</h4>
                  <div className="grid">
                    <img src="https://place-hold.it/200x200/F5ED85/2E4F4F.png&text=knot" alt='knot' ></img>
                    <div>
                      {Object.keys(properties(this.props.data)).slice(0,4).map((d,i)=>{
                        return <div className="property" key={"property-"+d}>
                          <h6>{labels[d]}</h6>
                          <p>{labels[this.props.data[d]]||"N/A"}</p>
                        </div>
                      })}
                    </div>
                    <div>
                      {Object.keys(properties(this.props.data)).slice(4,8).map((d,i)=>{
                        return <div className="property" key={"property-"+d}>
                          <h6>{labels[d]}</h6>
                          <p>{labels[this.props.data[d]]||"N/A"}</p>
                        </div>
                      })}
                    </div>
                  </div>
                </section> */}
                <section className="answer">
                  <h4>Description</h4>
                  <p dangerouslySetInnerHTML={{
                    __html: this.props.report.description.replace(this.props.data.line, '<span class="excerpt">'+this.props.data.line+'</span>')
                  }}>
                  </p>
                  <h4>Victim(s) Status</h4>
                  <p>{this.props.report.victim_status}</p>
                  <h4>Harasser(s) Status</h4>
                  <p>{this.props.report.harasser_status}</p>
                  <h4>Harasser(s) Gender</h4>
                  <p>{this.props.report.harasser_geneder}</p>
                  <h4>Institution(s) Type</h4>
                  <p>{this.props.report.institution_type}</p>
                  <h4>Institution(s) Name</h4>
                  <p>{this.props.report.institution_name}</p>
                  <h4>Victim(s) Field</h4>
                  <p>{this.props.report.field_victim}</p>
                  <h4>Institutional response</h4>
                  <p dangerouslySetInnerHTML={{
                    __html: this.props.report.institutional_response.replace(this.props.data.line, '<span class="excerpt">'+this.props.data.line+'</span>')
                  }}></p>
                  <h4>Consequences for harasser(s)</h4>
                  <p dangerouslySetInnerHTML={{
                    __html: this.props.report.harasser_consequences.replace(this.props.data.line, '<span class="excerpt">'+this.props.data.line+'</span>')
                  }}></p>
                  <h4>Impacts on Victim(s) career</h4>
                  <p dangerouslySetInnerHTML={{
                    __html: this.props.report.victim_career_impacts.replace(this.props.data.line, '<span class="excerpt">'+this.props.data.line+'</span>')
                  }}></p>
                  <h4>Impacts on Victim(s) Mental Health</h4>
                  <p dangerouslySetInnerHTML={{
                    __html: this.props.report.victim_mental_health.replace(this.props.data.line, '<span class="excerpt">'+this.props.data.line+'</span>')
                  }}></p>
                  <h4>Impacts on Victim(s) Life Choices</h4>
                  <p dangerouslySetInnerHTML={{
                    __html: this.props.report.victim_life_choices_impact.replace(this.props.data.line, '<span class="excerpt">'+this.props.data.line+'</span>')
                  }}></p>
                  <h4>General Comments</h4>
                  <p dangerouslySetInnerHTML={{
                    __html: this.props.report.general_comments.replace(this.props.data.line, '<span class="excerpt">'+this.props.data.line+'</span>')
                  }}></p>
                </section>
              </div>
            </div>
    );
    
  }
}

export default KnotInfo;

const allowed = ['type_of_abuse', 'hierarchy', 'time', 'at_conference', 'incident_reported', 'successful_report', 'stayed_in_academia', 'avoided_people_or_places'];
const properties = (raw) => {
  return Object.keys(raw)
  .filter(key => allowed.includes(key))
  .reduce((obj, key) => {
    obj[key] = raw[key];
    return obj;
  }, {});
}

const labels = Utilities.data_values_labels;

const example_data = {
  id: "R1005",
  line: "fueled by sexual interest rather than a genuine appreciation of my work",

  type_of_abuse: "verbal",
  hierarchy: "greater",
  time: "",
  at_conference: "FALSE",
  incident_reported: "FALSE",
  successful_report: "",
  stayed_in_academia: "",
  avoided_people_or_places: "FALSE",

  victim_hierarchy_0: "student",
  victim_hierarchy_1: "graduate",
  victim_hierarchy_2: "master student",
  victim_task: "",
  victim_office: "",
  harasser_hierarchy_0: "faculty",
  harasser_hierarchy_1: "",
  harasser_hierarchy_2: "",
  harasser_task: "",
  harasser_office: "",
  mds_incident_x: 209.9786194,
  mds_incident_y: -202.24260220000002,
  mds_victim_x: -469.3400114,
  mds_victim_y: 388.902053,
  mds_harasser_x: -245.9102684,
  mds_harasser_y: 480.5598312,
  x: 209.9786194,
  y: -202.24260220000002,
  knot: ["8", "16", "1", "2", "11"],
  r: 23.83275057562597,
  // points: (9) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}],
  index: 62,
  vy: 0,
  vx: 0,
  square_of_distance_from_center: 84993.08451968362,
  path_length: 139.69131469726562
}
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import * as d3 from 'd3';
import * as d3sl from 'd3-simple-slider';

import {ReactComponent as FiltersSVG} from './elements_grid.svg';

import { BsFillVolumeMuteFill, BsFillVolumeUpFill, BsQuestion } from 'react-icons/bs'

import Utilities from "../Utilities";
import { groupD8 } from 'pixi.js';

const class_filter_is_active = "filter-active";

class VisualizationTools extends Component {

  constructor(props) {
    super(props);
    this.resetFilter = this.resetFilter.bind(this);
  }

  resetFilter() {
    const innerDocument = document.getElementById('grid-btn').contentDocument;
    const activeElements = innerDocument.querySelectorAll(`.${class_filter_is_active}`);
    for (let i=0; i<activeElements.length; i++) {
      activeElements[i].classList.remove(class_filter_is_active);
      activeElements[i].querySelectorAll('circle')[0].style.fill = '#fafafa';
      activeElements[i].querySelectorAll('text')[0].style.fill = '#2E4F4F';
    }
    this.props.onFilter([]);
  }

  componentDidMount() {
    const this_props = this.props;
    
    document.getElementById('position-btns').addEventListener('load', function(){
      const positionBtns = document.getElementById('position-btns').contentDocument;

      const victims = positionBtns.getElementById('victims');
      victims.style.cursor = "pointer";
      victims.addEventListener('click', ()=>{this_props.repositionFunction("victim")});

      const incidents = positionBtns.getElementById('incidents');
      incidents.style.cursor = "pointer";
      incidents.addEventListener('click', ()=>{this_props.repositionFunction("incident")});

      const harassers = positionBtns.getElementById('harassers');
      harassers.style.cursor = "pointer";
      harassers.addEventListener('click', ()=>{this_props.repositionFunction("harasser")});

    }, false);

    // document.getElementById('grid-btn').addEventListener('load', function(){
      
      const gridBtn = document.getElementById('grid-btn')


      const properties = gridBtn.querySelectorAll('#grid > g');
      properties.forEach((d,i)=>{
        if (!properties[i].id) return;
        const values = gridBtn.querySelectorAll(`#${properties[i].id} > g`);
        for (var ii=0; ii<values.length; ii++) {
          values[ii].style.cursor = 'pointer';
          ['pointerenter'].forEach(type=>{
            values[ii].addEventListener(type, function() {
              addToolTip(this);
            })
          });
          ['pointerleave'].forEach(type=>{
            values[ii].addEventListener(type, function() {
              addToolTip();
            })
          });
          values[ii].addEventListener('click', function(){
            
            if (!this.classList.contains(class_filter_is_active))
            {
              this.classList.add(class_filter_is_active);
              this.querySelectorAll('circle')[0].style.fill = '#2E4F4F';
              this.querySelectorAll('text')[0].style.fill = '#fafafa';
              if (values.length === 2 || true)
              {
                const to_deactivate = gridBtn.querySelectorAll(`#${properties[i].id} > g.${class_filter_is_active}:not(#${this.id})`);
                for (let iii = 0; iii<to_deactivate.length; iii++)
                {
                  to_deactivate[iii].classList.remove(class_filter_is_active);
                  to_deactivate[iii].querySelectorAll('circle')[0].style.fill = '#fafafa';
                  to_deactivate[iii].querySelectorAll('text')[0].style.fill = '#2E4F4F';
                }
              }
            } else
            {
              this.classList.remove(class_filter_is_active);
              this.querySelectorAll('circle')[0].style.fill = '#fafafa';
              this.querySelectorAll('text')[0].style.fill = '#2E4F4F';
            }
            let active_filters = gridBtn.querySelectorAll(`#grid .${class_filter_is_active}`);
            active_filters = Array.from(active_filters);
            active_filters = active_filters.map(d=>{return { 'property': d.id.split('-')[0], 'value': d.id.split('-')[1] } })
            this_props.onFilter(active_filters);
          });
        }
      })

      function addToolTip(element) {
        d3.select(gridBtn)
          .selectAll('g.grid-tooltip').remove();

        if (element) {
          const x = d3.select(element).select('circle').attr('cx');
          const y = d3.select(element).select('circle').attr('cy');
          const r = d3.select(element).select('circle').attr('r');
          
          const padding = 5;
          const fontsize = 12;

          const tooltip = d3.select(gridBtn)
            .append('g')
            .classed('grid-tooltip',true)
            .attr('transform', `translate(${x}, ${y})`)
            .style('pointer-events','none');

          tooltip.append('rect')
            .attr('fill','#FAFAFA')
            .attr('stroke','#2E4F4F')
            .attr('height',fontsize+4)
            .attr('x',-padding)
            .attr('rx','3');

          tooltip.append('text')
            .attr('font-family','Inconsolata')
            .attr('font-size',fontsize)
            .attr('fill','#2E4F4F')
            .style('text-transform','uppercase')
            .attr('y',fontsize)
            .text( Utilities.data_values_labels[element.id.split('-')[1].replace(/X/g,' ')] );
          
          const boundingClientRect = tooltip.select('text').node().getBoundingClientRect();

          tooltip.select('rect').attr('width',boundingClientRect.width + padding*2);
          tooltip.attr('transform', `translate(${x - boundingClientRect.width/2}, ${y - 2*r - padding})`);

        }
      }

    // }, false);

    const slider_width = d3.select('#zoom-slider').node().getBoundingClientRect().width;
    const slider_height = d3.select('#zoom-slider').node().getBoundingClientRect().height;

    var sliderVertical = d3sl
      .sliderLeft()
      .min(Utilities.zoomOptions.minScale)
      .max(Utilities.zoomOptions.maxScale)
      .default(this.props.zoomLevel)
      .height(slider_height-30)
      .fill('#2E4F4F')
      .tickValues([0.2, 1, 2, 3])
      .displayFormat(d3.format('.0f'))
      .tickFormat(d3.format('.0f'))
      .handle(
        d3
          .symbol()
          .type(d3.symbolCircle)
          .size(535)()
      )
      .on('onchange', val => {
        this.props.onSliderZoom(val);
      });

    var gVertical = d3
      .select('#zoom-slider')
      .append('svg')
      .attr('width', slider_width)
      .attr('height', slider_height)
      .append('g')
      .attr('transform', 'translate('+(slider_width/2)+',15)');
  
    gVertical.call(sliderVertical);

    d3.select('#zoom-slider').selectAll('.track-inset, .track-overlay').remove();
    d3.select('#zoom-slider').select('.track')
      .attr('stroke-width', 2)
      .attr('stroke-linecap','square');

    d3.select('#zoom-slider').select('.track-fill')
      .attr('stroke-width', 3)
      .attr('stroke', 'var(--var-col-text)')
      .attr('stroke-linecap','square');

    d3.select('#zoom-slider').select('.handle')
      .attr('stroke', 'var(--var-col-text)');

    this.slider = sliderVertical;

    document.addEventListener("keypress", function(event) {
      if (event.key === "t") {
        const tools = document.getElementsByClassName('visualization-tools')[0]
        const newVisibility = tools.style.visibility === 'hidden' ? 'visible' : 'hidden';
        tools.style.visibility = newVisibility;
      }
    });

  }

  componentDidUpdate(prevProps) {
    if (prevProps.zoomLevel !== this.props.zoomLevel) {
      this.slider.silentValue(this.props.zoomLevel);
    }
  }
  render() {
    return (
      <div className="visualization-tools noselect">

        <div className="top">
          <div className="tool" style={{visibility:'visible'}}>
            <Link to="/" style={{textDecoration: 'none', textAlign: 'center'}}>
              <div>
                <button className="btn" onClick={()=>{}}>
                  <BsQuestion/>
                </button>
                <h5>About</h5>
              </div>
            </Link>
          </div>
          
          <div className="tool" style={{visibility:'hidden'}}>
            <div>
              <button className="btn" onClick={()=>{}}>
                <span role='img' aria-label='this is an emoji'>👀</span>
              </button>
              <h5>About</h5>
            </div>
          </div>
        </div>

        <div className="middle">
          <div className="tool" style={{alignItems:'flex-start'}}>
            <div style={{width:'4.5rem'}}>
              <h5>Zoom Control</h5>
              <div id="zoom-slider" style={{height:'33vh'}}>
                {/* here will be generated the slider */}
              </div>
              <h5>Auto Zoom</h5>
              <label className="switch">
                <input type="checkbox" defaultChecked={this.props.autoZoom} onChange={()=>this.props.onChangeAutoZoom(!this.props.autoZoom)}/>
                <span className="slider round"></span>
              </label>
            </div>
          </div>
        </div>

        <div className="bottom">

          <div className="tool">
              <div>
                <h5>Voices</h5>
                <button className="btn" onClick={()=>this.props.playFunction()}>
                    {this.props.play && <BsFillVolumeUpFill/>}
                    {!this.props.play && <BsFillVolumeMuteFill/>}
                </button>
              </div>
          </div>

          <div className="tool">
              <div>
                <h5 style={{whiteSpace: 'nowrap'}}>Elements position</h5>
                <object id="position-btns" className="no-select" type="image/svg+xml" data="./elements_positions.svg">
                    Your browser doesn't support SVG.
                </object>
              </div>
          </div>
          
          <div className="tool">
              <div style={{display:window.innerWidth<768?'none':'block'}}>
                <h5 style={{position:'relative'}}>Filter by data categories<span id="reset-filter" className="close" onClick={()=>this.resetFilter()}></span></h5>
                {/* <object style={{width:240,marginTop:-20}} id="grid-btn" className="no-select" type="image/svg+xml" data="./elements_grid_inline_style.svg">
                    Your browser doesn't support SVG.
                </object> */}
                <FiltersSVG id="grid-btn" />
              </div>
          </div>

        </div>
      </div>
    );
  }
}

export default VisualizationTools;

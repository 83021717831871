import d3ToPng from 'd3-svg-to-png';
import * as d3 from 'd3';
import { DATA_URI } from 'pixi.js';
import fs from 'fs'

const grid_1 = [
  { "number": "1", "order": "1", "get_dx": r=>0, "get_dy": r=>0, "property": "hierarchy", "value": "mixed" },
  { "number": "2", "order": "1", "get_dx": r=>0, "get_dy": r=>2*r, "property": "time", "value": "business hours" },
  { "number": "3", "order": "1", "get_dx": r=>2*r*Math.cos(Math.PI / 6), "get_dy": r=>2*r*Math.sin(Math.PI / 6), "property": "hierarchy", "value": "equal" },
  { "number": "4", "order": "1", "get_dx": r=>2*r*Math.cos(Math.PI / 6), "get_dy": r=>-2*r*Math.sin(Math.PI / 6), "property": "at_conference", "value": "FALSE" },
  { "number": "5", "order": "1", "get_dx": r=>0, "get_dy": r=>-2*r, "property": "at_conference", "value": "TRUE" },
  { "number": "6", "order": "1", "get_dx": r=>-2*r*Math.cos(Math.PI / 6), "get_dy": r=>-2*r*Math.sin(Math.PI / 6), "property": "hierarchy", "value": "lesser" },
  { "number": "7", "order": "1", "get_dx": r=>-2*r*Math.cos(Math.PI / 6), "get_dy": r=>2*r*Math.sin(Math.PI / 6), "property": "time", "value": "after business hours" },
  { "number": "8", "order": "2", "get_dx": r=>0, "get_dy": r=>2*2*r, "property": "type_of_abuse", "value": "physical" },
  { "number": "9", "order": "3", "get_dx": r=>2*r*Math.cos(Math.PI / 6), "get_dy": r=>2*r + 2*r*Math.sin(Math.PI / 6), "property": "type_of_abuse", "value": "both" },
  { "number": "10", "order": "1", "get_dx": r=>2 * 2*r*Math.cos(Math.PI / 6), "get_dy": r=>2*r, "property": "hierarchy", "value": "greater" },
  { "number": "11", "order": "1", "get_dx": r=>2 * 2*r*Math.cos(Math.PI / 6), "get_dy": r=>0, "property": "stayed_in_academia", "value": "TRUE" },
  { "number": "12", "order": "1", "get_dx": r=>2 * 2*r*Math.cos(Math.PI / 6), "get_dy": r=>-2*r, "property": "stayed_in_academia", "value": "FALSE" },
  { "number": "13", "order": "1", "get_dx": r=>2*r*Math.cos(Math.PI / 6), "get_dy": r=>-2 * 3*r*Math.sin(Math.PI / 6), "property": "avoided_people_or_places", "value": "FALSE" },
  { "number": "14", "order": "1", "get_dx": r=>0, "get_dy": r=>-2 * 2*r, "property": "avoided_people_or_places", "value": "TRUE" },
  { "number": "15", "order": "1", "get_dx": r=>-2*r*Math.cos(Math.PI / 6), "get_dy": r=>-2 * 3*r*Math.sin(Math.PI / 6), "property": "successful_report", "value": "FALSE" },
  { "number": "16", "order": "1", "get_dx": r=>-2 * 2*r*Math.cos(Math.PI / 6), "get_dy": r=>-2*r, "property": "successful_report", "value": "TRUE" },
  { "number": "17", "order": "1", "get_dx": r=>-2 * 2*r*Math.cos(Math.PI / 6), "get_dy": r=>0, "property": "incident_reported", "value": "FALSE" },
  { "number": "18", "order": "1", "get_dx": r=>-2*2*r*Math.cos(Math.PI / 6), "get_dy": r=>2*r, "property": "incident_reported", "value": "TRUE" },
  { "number": "19", "order": "1", "get_dx": r=>-2*r*Math.cos(Math.PI / 6), "get_dy": r=>2*r + 2*r*Math.sin(Math.PI / 6), "property": "type_of_abuse", "value": "verbal" }
]

const grid_2 = [

  { "number": "14", "order": "1", "get_dx": r=>0, "get_dy": r=>-2 * 2*r, "property": "type_of_abuse", "value": "verbal" },
  { "number": "8", "order": "2", "get_dx": r=>0, "get_dy": r=>2*2*r, "property": "type_of_abuse", "value": "physical" },
  { "number": "17", "order": "1", "get_dx": r=>-2 * 2*r*Math.cos(Math.PI / 6), "get_dy": r=>0, "property": "type_of_abuse", "value": "both" },

  { "number": "16", "order": "1", "get_dx": r=>-2 * 2*r*Math.cos(Math.PI / 6), "get_dy": r=>-2*r, "property": "hierarchy", "value": "greater" },
  { "number": "12", "order": "1", "get_dx": r=>2 * 2*r*Math.cos(Math.PI / 6), "get_dy": r=>-2*r, "property": "hierarchy", "value": "equal" },
  { "number": "18", "order": "1", "get_dx": r=>-2*2*r*Math.cos(Math.PI / 6), "get_dy": r=>2*r, "property": "hierarchy", "value": "mixed" },
  { "number": "10", "order": "1", "get_dx": r=>2 * 2*r*Math.cos(Math.PI / 6), "get_dy": r=>2*r, "property": "hierarchy", "value": "lesser" },

  { "number": "5", "order": "1", "get_dx": r=>0, "get_dy": r=>-2*r, "property": "time", "value": "business hours" },
  { "number": "2", "order": "1", "get_dx": r=>0, "get_dy": r=>2*r, "property": "time", "value": "after business hours" },

  { "number": "1", "order": "1", "get_dx": r=>0, "get_dy": r=>0, "property": "at_conference", "value": "TRUE" },
  { "number": "11", "order": "1", "get_dx": r=>2 * 2*r*Math.cos(Math.PI / 6), "get_dy": r=>0, "property": "at_conference", "value": "FALSE" },

  { "number": "15", "order": "1", "get_dx": r=>-2*r*Math.cos(Math.PI / 6), "get_dy": r=>-2 * 3*r*Math.sin(Math.PI / 6), "property": "incident_reported", "value": "TRUE" },
  { "number": "4", "order": "1", "get_dx": r=>2*r*Math.cos(Math.PI / 6), "get_dy": r=>-2*r*Math.sin(Math.PI / 6), "property": "incident_reported", "value": "FALSE" },

  { "number": "7", "order": "1", "get_dx": r=>-2*r*Math.cos(Math.PI / 6), "get_dy": r=>2*r*Math.sin(Math.PI / 6), "property": "successful_report", "value": "TRUE" },
  { "number": "9", "order": "3", "get_dx": r=>2*r*Math.cos(Math.PI / 6), "get_dy": r=>2*r + 2*r*Math.sin(Math.PI / 6), "property": "successful_report", "value": "FALSE" },    
  
  { "number": "19", "order": "1", "get_dx": r=>-2*r*Math.cos(Math.PI / 6), "get_dy": r=>2*r + 2*r*Math.sin(Math.PI / 6), "property": "stayed_in_academia", "value": "TRUE" },
  { "number": "3", "order": "1", "get_dx": r=>2*r*Math.cos(Math.PI / 6), "get_dy": r=>2*r*Math.sin(Math.PI / 6), "property": "stayed_in_academia", "value": "FALSE" },
  
  { "number": "6", "order": "1", "get_dx": r=>-2*r*Math.cos(Math.PI / 6), "get_dy": r=>-2*r*Math.sin(Math.PI / 6), "property": "avoided_people_or_places", "value": "TRUE" },
  { "number": "13", "order": "1", "get_dx": r=>2*r*Math.cos(Math.PI / 6), "get_dy": r=>-2 * 3*r*Math.sin(Math.PI / 6), "property": "avoided_people_or_places", "value": "FALSE" },
  
]

const grid_3 = [

  { "number": "14", "get_dx": r=>0, "get_dy": r=>-2 * 2*r, "property": "successful_report", "value": "TRUE" },
  { "number": "13", "get_dx": r=>2*r*Math.cos(Math.PI / 6), "get_dy": r=>-2 * 3*r*Math.sin(Math.PI / 6), "property": "successful_report", "value": "FALSE" },

  { "number": "12", "get_dx": r=>2 * 2*r*Math.cos(Math.PI / 6), "get_dy": r=>-2*r, "property": "avoided_people_or_places", "value": "TRUE" },
  { "number": "11", "get_dx": r=>2 * 2*r*Math.cos(Math.PI / 6), "get_dy": r=>0, "property": "avoided_people_or_places", "value": "FALSE" },

  { "number": "16", "get_dx": r=>-2 * 2*r*Math.cos(Math.PI / 6), "get_dy": r=>-2*r, "property": "hierarchy", "value": "greater" },
  { "number": "15", "get_dx": r=>-2*r*Math.cos(Math.PI / 6), "get_dy": r=>-2 * 3*r*Math.sin(Math.PI / 6), "property": "hierarchy", "value": "equal" },
  { "number": "5", "get_dx": r=>0, "get_dy": r=>-2*r, "property": "hierarchy", "value": "mixed" },
  { "number": "4", "get_dx": r=>2*r*Math.cos(Math.PI / 6), "get_dy": r=>-2*r*Math.sin(Math.PI / 6), "property": "hierarchy", "value": "lesser" },
  
  { "number": "17", "get_dx": r=>-2 * 2*r*Math.cos(Math.PI / 6), "get_dy": r=>0, "property": "time", "value": "business hours" },
  { "number": "18", "get_dx": r=>-2*2*r*Math.cos(Math.PI / 6), "get_dy": r=>2*r, "property": "time", "value": "after business hours" },

  { "number": "6", "get_dx": r=>-2*r*Math.cos(Math.PI / 6), "get_dy": r=>-2*r*Math.sin(Math.PI / 6), "property": "at_conference", "value": "TRUE" },
  { "number": "7", "get_dx": r=>-2*r*Math.cos(Math.PI / 6), "get_dy": r=>2*r*Math.sin(Math.PI / 6), "property": "at_conference", "value": "FALSE" },

  { "number": "1", "get_dx": r=>0, "get_dy": r=>0, "property": "incident_reported", "value": "TRUE" },
  { "number": "2", "get_dx": r=>0, "get_dy": r=>2*r, "property": "incident_reported", "value": "FALSE" },
  
  { "number": "3", "get_dx": r=>2*r*Math.cos(Math.PI / 6), "get_dy": r=>2*r*Math.sin(Math.PI / 6), "property": "stayed_in_academia", "value": "TRUE" },
  { "number": "10", "get_dx": r=>2 * 2*r*Math.cos(Math.PI / 6), "get_dy": r=>2*r, "property": "stayed_in_academia", "value": "FALSE" },

  { "number": "19", "get_dx": r=>-2*r*Math.cos(Math.PI / 6), "get_dy": r=>2*r + 2*r*Math.sin(Math.PI / 6), "property": "type_of_abuse", "value": "physical" },
  { "number": "8", "get_dx": r=>0, "get_dy": r=>2*2*r, "property": "type_of_abuse", "value": "verbal" },
  { "number": "9", "get_dx": r=>2*r*Math.cos(Math.PI / 6), "get_dy": r=>2*r + 2*r*Math.sin(Math.PI / 6), "property": "type_of_abuse", "value": "both" },
  
]

const grid_4 = [

  { "number": "14", "get_dx": r=>0, "get_dy": r=>-2 * 2*r, "property": "successful_report", "value": "TRUE" },
  { "number": "13", "get_dx": r=>2*r*Math.cos(Math.PI / 6), "get_dy": r=>-2 * 3*r*Math.sin(Math.PI / 6), "property": "successful_report", "value": "FALSE" },

  { "number": "12", "get_dx": r=>2 * 2*r*Math.cos(Math.PI / 6), "get_dy": r=>-2*r, "property": "avoided_people_or_places", "value": "TRUE" },
  { "number": "11", "get_dx": r=>2 * 2*r*Math.cos(Math.PI / 6), "get_dy": r=>0, "property": "avoided_people_or_places", "value": "FALSE" },

  { "number": "16", "get_dx": r=>-2 * 2*r*Math.cos(Math.PI / 6), "get_dy": r=>-2*r, "property": "hierarchy", "value": "greater" },
  { "number": "15", "get_dx": r=>-2*r*Math.cos(Math.PI / 6), "get_dy": r=>-2 * 3*r*Math.sin(Math.PI / 6), "property": "hierarchy", "value": "equal" },
  { "number": "5", "get_dx": r=>0, "get_dy": r=>-2*r, "property": "hierarchy", "value": "mixed" },
  { "number": "4", "get_dx": r=>2*r*Math.cos(Math.PI / 6), "get_dy": r=>-2*r*Math.sin(Math.PI / 6), "property": "hierarchy", "value": "lesser" },
  
  { "number": "17", "get_dx": r=>-2 * 2*r*Math.cos(Math.PI / 6), "get_dy": r=>0, "property": "time", "value": "business hours" },
  { "number": "18", "get_dx": r=>-2*2*r*Math.cos(Math.PI / 6), "get_dy": r=>2*r, "property": "time", "value": "after business hours" },

  { "number": "6", "get_dx": r=>-2*r*Math.cos(Math.PI / 6), "get_dy": r=>-2*r*Math.sin(Math.PI / 6), "property": "at_conference", "value": "TRUE" },
  { "number": "1", "get_dx": r=>0, "get_dy": r=>0, "property": "at_conference", "value": "FALSE" },

  { "number": "7", "get_dx": r=>-2*r*Math.cos(Math.PI / 6), "get_dy": r=>2*r*Math.sin(Math.PI / 6), "property": "incident_reported", "value": "TRUE" },
  { "number": "2", "get_dx": r=>0, "get_dy": r=>2*r, "property": "incident_reported", "value": "FALSE" },
  
  { "number": "3", "get_dx": r=>2*r*Math.cos(Math.PI / 6), "get_dy": r=>2*r*Math.sin(Math.PI / 6), "property": "stayed_in_academia", "value": "TRUE" },
  { "number": "10", "get_dx": r=>2 * 2*r*Math.cos(Math.PI / 6), "get_dy": r=>2*r, "property": "stayed_in_academia", "value": "FALSE" },

  { "number": "19", "get_dx": r=>-2*r*Math.cos(Math.PI / 6), "get_dy": r=>2*r + 2*r*Math.sin(Math.PI / 6), "property": "type_of_abuse", "value": "physical" },
  { "number": "8", "get_dx": r=>0, "get_dy": r=>2*2*r, "property": "type_of_abuse", "value": "verbal" },
  { "number": "9", "get_dx": r=>2*r*Math.cos(Math.PI / 6), "get_dy": r=>2*r + 2*r*Math.sin(Math.PI / 6), "property": "type_of_abuse", "value": "both" },
  
]

const Utilities = {};

Utilities.knots_grid_property_value = (property, value) => {
    return grid_4.find(el=>el.property===property&&el.value===value);
}

Utilities.knots_grid_number = (number) => {
    return grid_4.find(el=>el.number===number);
}

Utilities.returnGrid = () => {
    return grid_4;
}
Utilities.returnGridSorted = () => {
  return grid_4.sort((a,b)=>+a.order - b.order);
}
/**
 *  @param {Element} element - the knot element
 *  @param {{line:string,id:string}} data - the update data
 *  @param {scale:number} element - scale factor
 *  this is a async function and must be used with await.
 */
Utilities.toImage = async (element, data, scale = 1) => {
  const size = element.getBoundingClientRect();
  let svg = d3
    .select("body")
    .append("svg")
    .attr("id", "utilToImage")
    .attr("width", size.width)
    .attr("height", size.height)
    .style("position", "absolute")
    .style("top", 0)
    .style("left", 0);
  let knot = svg.append('g')
    .attr("transform", `translate(${size.width/2},${size.height/2})`)
    .html(d3.select(element).html());
  // get rid of those elements, we will redraw them in PIXIjs
  knot.selectAll('.circle-area, .value').remove();
  // bring back the text
  knot.selectAll('.txt-line').style('display','block').style('opacity',1);
  // scale the stroke
  knot.selectAll('.knot-path').attr('stroke-width',1/scale)

  // window.prompt("download and remove", element.id);
  const dpi = 2;
  const fileName = element.id + '@' + dpi + 'x';
  console.log('download', data.id)
  await d3ToPng("#utilToImage", fileName, { quality: 1, scale: dpi });
  svg.remove();
};


Utilities.toImageRecursive = (data) => {
  const dpi = 2;
  let scale = Utilities.zoomOptions.maxScale;

  function delay(t, v) {
    return new Promise(function(resolve) { 
        setTimeout(resolve.bind(null, v), t)
    });
  }

  Promise.prototype.delay = function(t) {
    return this.then(function(v) {
        return delay(t, v);
    });
  }
  
  let counter = 0;
  download(counter);
  function download(i) {
    let element = d3.select('g#node-'+data[i].id).node();
    let svg = d3
      .select("body")
      .append("svg")
      .attr("id", "utilToImage")
      .style("position", "absolute")
      .style("top", 0)
      .style("left", 0)
      .attr("width",300)
      .attr("height",150);

    let knot = svg.append('g')
      .html(d3.select(element).html());

    // render all elements
    knot.selectAll('.circle-area').style('display','block').style('opacity',1);
    knot.selectAll('.value').style('display','block').style('opacity',1);
    knot.selectAll('.knot-path').style('display','block').style('opacity',1);
    knot.selectAll('.txt-line').style('display','block').style('opacity',1);

    // scale the stroke
    knot.selectAll('.knot-path').attr('stroke-width',1/scale);

    const size = knot.node().getBoundingClientRect();

    svg.attr("width", size.width)
      .attr("height", size.height);

    knot.attr("transform", `translate(${size.width/2},${size.height/2})`);

    // control visibility of elements
    knot.selectAll('.circle-area').style('display','block').style('opacity',0);
    knot.selectAll('.value').style('display','block').style('opacity',0);
    knot.selectAll('.knot-path').style('display','block').style('opacity',0);
    knot.selectAll('.txt-line').style('display','block').style('opacity',1).attr('fill','white');
    knot.selectAll('.txt-line').selectAll('textPath').attr('fill','white');
  
    // window.prompt("download and remove", element.id);
    const fileName = element.id + '@' + dpi + 'x';

    // Need delay because the browser skips some downloads, can't understand why
    d3ToPng("#utilToImage", fileName, { quality: 1, scale: dpi*scale }).delay(500).then(fileData=>{
      svg.remove();
      console.log(i, 'downloaded', data[i].id);
      counter++;
      if (counter < data.length) {
        download(counter);
      }
    });
  }

}

Utilities.zoomOptions = {
  minScale:0.1,
  maxScale:3
}

Utilities.knot_line = d3.line()
  .curve(d3.curveCatmullRom.alpha(1))
  .x(d=>d.x)
  .y(d=>d.y);

Utilities.size = d3.scalePow()
  .exponent(0.5)
  .range([0,4]);

Utilities.knot_settings = {
  fontSize: '0.38rem',
  fontSizeAugmented: '0.475rem',
  dY:-2,
  animationDuration: 500,
  annotationSize: '0.3rem',
  annotationStrokeWidth: .75,
  annotationStrokeDasharray: '0 3',
  annotationLinecap: 'round'
}

Utilities.get_knot_points = (data) => {

  let grid_numbers_arr = data.knot
  let r = data.r/5
  let supposed_length = data.supposed_knot_length
  let random_variations = []


  let points = [];    
  for (let ii=0; ii<grid_numbers_arr.length; ii++) {
      let n = grid_numbers_arr[ii];
      let obj = {
        'number': n,
        'x':Utilities.knots_grid_number(n).get_dx(r),
        'y':Utilities.knots_grid_number(n).get_dy(r)
      }
      if (random_variations.length) {
          obj.x += random_variations[ii];
          obj.y += random_variations[ii];
      }
      points.push(obj)
    }
    points.unshift(
        {
          'number': "-1",
          'x':-3.5*2*r*Math.cos(Math.PI/6),
          'y':0
        },
        {
          'number': "0",
          'x':-3*2*r*Math.cos(Math.PI/6),
          'y':0
        }
    )
    points.push(
        {
          'number': "20",
          'x':3*2*r*Math.cos(Math.PI/6),
          'y':0
        },
        {
          'number': "21",
          'x':3.5*2*r*Math.cos(Math.PI/6),
          'y':0
        }
      )


  return points;
}

Utilities.colors = {
  'victim': {
    'texts': '#2E4F4F',
    'lines': '#2E4F4F',
    'background': '#FAFAFA',
    'strokes': '#F5ED85'
  },
  'incident': {
    'texts': '#2E4F4F',
    'lines': '#2E4F4F',
    'background': '#F5ED85',
    'strokes': '#FAFAFA'
  },
  'harasser': {
    'texts': '#FAFAFA',
    'lines': '#FAFAFA',
    'background': '#626666',
    'strokes': '#FAFAFA'
  }
}

Utilities.data_values_labels = {
  "type_of_abuse":"Abuse",
  "physical":"Physical",
  "verbal":"Verbal",
  "both":"Verbal and physical",

  "hierarchy":"Hierarchy",
  "greater": "Higher",
  "equal": "Equal",
  "lesser": "Lower",
  "mixed": "Mixed",

  "time":"Business hours",
  "business hours":"During",
  "after business hours":"After",

  "at_conference":"At conference",
  "incident_reported":"Case reported",
  "successful_report":"Report successful",
  "stayed_in_academia":"Stayed in academia",
  "avoided_people_or_places":"Avoided people or places",
  "TRUE": "Yes",
  "FALSE": "No",

  "N/A": "Unknown"
}

// values will be multiplied by knots radii
Utilities.annotations_positions = {
  type_of_abuse: { x:1.25, y:1 },
  hierarchy: { x:-0.75, y:-2 },
  time: { x:-1.5, y:0 },
  at_conference: { x:-1.25, y:-1 },

  incident_reported: { x:-1.25, y:1 },
  successful_report: { x:0.75, y:-2 },
  stayed_in_academia: { x:1.5, y:0 },
  avoided_people_or_places: { x:1.25, y:-1 }
}

export default Utilities;